/*
@File: Tarn Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader Area CSS
** - Navbar Area CSS
** - Main Banner Area CSS
** - Solutions Area CSS
** - About Area CSS
** - Our Mission Area CSS
** - Boxes Area CSS
** - Funfacts Area CSS
** - What We Do Area CSS
** - History Area CSS
** - Services Area CSS
** - Services Details Area CSS
** - Featured Services Area CSS
** - How It's Work Area CSS
** - Courses Area CSS
** - Courses Details Area CSS
** - Why Choose Us Area CSS
** - Case Study Area CSS
** - Projects Area CSS
** - CaseStudy/Projects Details Area CSS
** - Scientist Area CSS
** - Explore Learning Area CSS
** - FAQ Area CSS
** - Industries Serve Area CSS
** - Gallery Area CSS
** - Products Area CSS
** - Products Details Area CSS
** - Cart Area CSS
** - Checkout Area CSS
** - Upcoming Bootcamps Area CSS
** - Events Area CSS
** - Events Details Area CSS
** - 404 Error Area CSS
** - Testimonials Area CSS
** - Partner Area CSS
** - Blog Area CSS
** - Blog Details Area CSS
** - Project Start Area CSS
** - Page Title Area CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Profile Authentication Area CSS
** - Coming Soon Area CSS
** - Privacy Policy Area CSS
** - Terms of Service Area CSS
** - Purchase Guide Area CSS
** - Membership Levels Area CSS
** - Contact Area CSS
** - Subscribe Area CSS
** - Footer Area CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/

$main-color: #ff5d22;
$white-color: #ffffff;
$black-color: #221638;
$optional-color: #6b6b84;
$font-size: 17px;
$transition: .5s;

body {
    padding: 0;
    margin: 0;
    font: {
        size: $font-size;
    };
}
img {
    max-width: 100%;
    height: auto;
}
:focus {
    outline: 0 !important;
}
.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font: {
        weight: 800;
    };
}
p {
    color: $optional-color;
    line-height: 1.8;
    margin-bottom: 15px;
    font: {
        weight: 600;
        size: $font-size;
    };
    &:last-child {
        margin-bottom: 0;
    }
}
a {
    color: $black-color;
    text-decoration: none;
    transition: $transition;

    &:hover {
        color: $main-color;
        text-decoration: none;
    }
}
.form-group {
    margin-bottom: 20px;
}
.ptb-100 {
    padding: {
        top: 100px;
        bottom: 100px;
    };
}
.pt-100 {
    padding-top: 100px;
}
.pb-100 {
    padding-bottom: 100px;
}
.ptb-70 {
    padding: {
        top: 70px;
        bottom: 70px;
    };
}
.pt-70 {
    padding-top: 70px;
}
.pb-70 {
    padding-bottom: 70px;
}
.bg-e3fbff {
    background-color: #e3fbff;
}
.bg-f1f8fb {
    background-color: #f1f8fb;
}
.bg-fffbf5 {
    background-color: #fffbf5;
}
.bg-f4f7fe {
    background-color: #f4f7fe;
}
.bg-fafafb {
    background-color: #fafafb;
}
.bg-fafafa {
    background-color: #fafafa;
}
/*default-btn*/
.default-btn {
    border: none;
    position: relative;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    z-index: 1;
    color: $white-color;
    background-color: $main-color;
    transition: $transition;
    border-radius: 5px;
    padding: 12px 25px 12px 50px;
    font: {
        weight: 700;
        size: 16px;
    };
    span {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-radius: 50%;
        background-color: $black-color;
        transition: width 0.5s ease-in-out, height 0.5s ease-in-out;
        transform: translate(-50%, -50%);
        z-index: -1;
        border-radius: 5px;
    }
    i {
        position: absolute;
        left: 25px;
        top: 50%;
        transform: translateY(-50%);
        font: {
            weight: 700;
            size: 16.5px;
        };
        &.flaticon-user {
            margin-top: -1.5px;
        }
    }
    &:hover {
        color: $white-color;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);

        span {
            width: 225%;
            height: 562.5px;
        }
    }
}
/*section-title*/
.section-title {
    text-align: center;
    max-width: 700px;
    margin: {
        left: auto;
        right: auto;
        bottom: 55px;
    };
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 0;
        position: relative;
        display: inline-block;
        font: {
            size: 44px;
        };
        .overlay {
            position: absolute;
            left: 0;
            top: 0;
            background-color: $black-color;
            width: 100%;
            height: 100%;
            z-index: 1;
        }
    }
    p {
        display: block;
        max-width: 600px;
        margin: {
            left: auto;
            right: auto;
            top: 12px;
        };
    }
}
/*form-control*/
.form-control {
    height: 50px;
    color: $black-color;
    box-shadow: unset !important;
    border: 1px solid #f5f5f5;
    background-color: #f5f5f5;
    transition: $transition;
    border-radius: 3px;
    padding: 1px 0 0 15px;
    font: {
        size: 16px;
        weight: 600;
    };
    &::placeholder {
        color: #7e7e7e;
        transition: $transition;
    }
    &:focus {
        border-color: $main-color;
        background-color: transparent;

        &::placeholder {
            color: transparent;
        }
    }
}
textarea.form-control {
    height: auto;
    padding-top: 15px;
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader-deactivate {
    display: none;
}
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background-color: $white-color;

    .loader {
        left: 50%;
        top: 46%;
        transform: translateX(-50%) translateY(-46%);
        position: absolute;
        
        .sbl-half-circle-spin {
            height: 80px;
            width: 80px;
            color: $main-color;
            display: inline-block;
            position: relative;
            border: 1px solid;
            border-radius: 50%;
            animation: animeCircleSpin 5s ease-in-out infinite reverse;

            &::after {
                content: '';
                border: 6px solid;
                position: absolute;
                left: 10px;
                top: 15px;
                border-radius: inherit;
            }
            div {
                height: 50%;
                width: 50%;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                border: 1px solid;
                border-radius: 50%;
                animation: animeCircleSpin 4s ease-in-out infinite;

                &::before {
                    height: 0;
                    width: 0;
                    content: '';
                    border-radius: 50%;
                    display: block;
                    transform: rotate(-45deg);
                    border: 19px solid;
                    border: {
                        right-color: transparent;
                        bottom-color: transparent;
                    };
                }
            }
        }
    }
}
@keyframes animeCircleSpin {
    0% {
        transform: rotate(0);
    }
    50% {
        transform: rotate(720deg);
    }
    100% {
        transform: rotate(0);
    }
}

/*================================================
Navbar Area CSS
=================================================*/
.tarn-nav {
    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        };
    }
    .navbar {
        position: inherit;
        padding: {
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        };      
        .navbar-brand {
            font-size: inherit;
            line-height: 1;
            padding: {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
        }
        .navbar-nav {
            margin: {
                left: auto;
            };
            .nav-item {
                position: relative;
                margin: {
                    left: 14px;
                    right: 14px;
                };
                a {
                    color: $black-color;
                    font: {
                        size: $font-size;
                        weight: 700;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                        top: 25px;
                        bottom: 25px;
                    };
                    i {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                        display: inline-block;
                        margin: {
                            left: -4px;
                            right: -3px;
                        };
                    }
                    &:hover, &:focus, &.active {
                        color: $main-color;
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                &:first-child {
                    margin-left: 0;
                }
                &:hover, &.active {
                    a {
                        color: $main-color;
                    }
                }
                .dropdown-menu {
                    border: none;
                    top: 70px;
                    left: 0;
                    z-index: 99;
                    opacity: 0;
                    width: 300px;
                    display: block;
                    border-radius: 0;
                    padding: 10px 0;
                    margin-top: 15px;
                    position: absolute;
                    visibility: hidden;
                    background: $white-color;
                    transition: all 0.2s ease-in-out;
                    border-top: 3px solid $main-color;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);

                    li {
                        margin: 0;
                        
                        a {
                            padding: 8px 20px;
                            position: relative;
                            display: block;
                            color: $black-color;
                            font: {
                                size: 16px;
                                weight: 700;
                            };
                            i {
                                margin: 0;
                                position: absolute;
                                top: 50%;
                                font-size: 20px;
                                transform: translateY(-50%);
                                right: 15px;
                            }
                            &:hover, &:focus, &.active {
                                color: $main-color;
                            }
                        }
                        .dropdown-menu {
                            top: 0;
                            opacity: 0;
                            left: -300px;
                            margin-top: 15px;
                            visibility: hidden;
                            
                            li {
                                a {
                                    color: $black-color;
                                    
                                    &:hover, &:focus, &.active {
                                        color: $main-color;
                                    }
                                }
                                .dropdown-menu {
                                    top: 0;
                                    opacity: 0;
                                    left: 300px;
                                    visibility: hidden;
        
                                    li {
                                        a {
                                            color: $black-color;
        
                                            &:hover, &:focus, &.active {
                                                color: $main-color;
                                            }
                                        }
                                        .dropdown-menu {
                                            top: 0;
                                            opacity: 0;
                                            left: -300px;
                                            visibility: hidden;
                
                                            li {
                                                a {
                                                    color: $black-color;
                
                                                    &:hover, &:focus, &.active {
                                                        color: $main-color;
                                                    }
                                                }
                                                .dropdown-menu {
                                                    top: 0;
                                                    opacity: 0;
                                                    left: 300px;
                                                    visibility: hidden;
                        
                                                    li {
                                                        a {
                                                            color: $black-color;
                
                                                            &:hover, &:focus, &.active {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        .dropdown-menu {
                                                            top: 0;
                                                            opacity: 0;
                                                            left: -300px;
                                                            visibility: hidden;
                                
                                                            li {
                                                                a {
                                                                    color: $black-color;
                
                                                                    &:hover, &:focus, &.active {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                .dropdown-menu {
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    left: 300px;
                                                                    visibility: hidden;
                                        
                                                                    li {
                                                                        a {
                                                                            color: $black-color;
                
                                                                            &:hover, &:focus, &.active {
                                                                                color: $main-color;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                &.active {
                                                                    a {
                                                                        color: $main-color;
                                                                    }
                                                                }
                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                        &.active {
                                                            a {
                                                                color: $main-color;
                                                            }
                                                        }
                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                            }
                                                        }
                                                    }
                                                }
                                                &.active {
                                                    a {
                                                        color: $main-color;
                                                    }
                                                }
                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                    }
                                                }
                                            }
                                        }
                                        &.active {
                                            a {
                                                color: $main-color;
                                            }
                                        }
                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                            }
                                        }
                                    }
                                }
                                &.active {
                                    a {
                                        color: $main-color;
                                    }
                                }
                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                    }
                                }
                            }
                        }
                        &.active {
                            a {
                                color: $main-color;
                            }
                        }
                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                margin-top: 0;
                            }
                        }
                    }
                }
                &:hover {
                    .dropdown-menu {
                        opacity: 1;
                        visibility: visible;
                        margin-top: 0;
                    }
                }
                &.megamenu {
                    position: inherit;

                    .container {
                        display: block;
                        flex-wrap: unset;
                        align-items: unset;
                        justify-content: unset;
                    }
                    .dropdown-menu {
                        width: 100%;
                        top: auto;
                        left: 0;
                        margin-top: 0;
                        padding: 30px 0;
                        position: absolute;
                        transform: unset !important;

                        .megamenu-submenu {
                            li {
                                a {
                                    margin-top: 14px;
                                    color: $black-color;
                                    padding: 0;
                                    display: block;
                                    font: {
                                        size: 16.4px;
                                        weight: 700;
                                    };
                                    &:hover, &.active {
                                        color: $main-color;
                                    }
                                }
                                &:first-child {
                                    a {
                                        margin-top: 0;
                                    }
                                }
                            }
                        }
                        .single-category-widget {
                            position: relative;
                            border-top: 1px solid #eeeeee;
                            padding-top: 30px;
                            margin: {
                                top: 30px;
                            };
                            .icon {
                                width: 70px;
                                height: 70px;
                                position: relative;
                                background: #f4f4f4;
                                text-align: center;
                                font-size: 40px;
                                transition: $transition;
                                color: $black-color;
                                margin-bottom: 15px;
                                border: {
                                    width: 1.5px;
                                    style: dashed;
                                    color: #eeeeee;
                                    radius: 50%;
                                };
                                i {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 0;
                                    right: 0;
                                }
                            }
                            h3 {
                                margin-bottom: 0;
                                font: {
                                    size: 20px;
                                    weight: 800;
                                };
                            }
                            .sub-title {
                                display: block;
                                margin-top: 5px;
                                color: $main-color;
                                font: {
                                    weight: 600;
                                    size: 15px;
                                };
                            }
                            .link-btn {
                                position: absolute;
                                display: block;
                                left: 0;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                z-index: 2;
                            }
                            &:hover {
                                .icon {
                                    background-color: $main-color;
                                    border-color: $white-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .others-option {
            margin-left: 30px;

            .option-item {
                margin-left: 30px;

                &:first-child {
                    margin-left: 0;
                }
                .search-box {
                    position: relative;
                    width: 300px;
                    border-radius: 5px;
        
                    .input-search {
                        display: block;
                        width: 100%;
                        height: 48px;
                        border: none;
                        border-radius: 5px;
                        background-color: #f5f5f5;
                        transition: $transition;
                        color: $black-color;
                        font: {
                            size: 16px;
                            weight: 400;
                        };
                        padding: {
                            left: 15px;
                            top: 1px;
                            bottom: 0;
                            right: 0;
                        };
                        &::placeholder {
                            color: #848484;
                            transition: $transition;
                        }
                        &:focus {
                            border-color: $main-color;
            
                            &::placeholder {
                                color: transparent;
                            }
                        }
                    }
                    button {
                        position: absolute;
                        right: 0;
                        top: 0;
                        height: 48px;
                        background-color: transparent;
                        border: none;
                        color: $main-color;
                        border-radius: 5px;
                        font-size: 22px;
                        padding: 0 15px;
                    }
                }
                .default-btn {
                    margin-bottom: -6px;
                }
            }
        }
    }
}
.navbar-area {
    position: absolute;
    background-color: transparent;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: 999;
    padding: {
        top: 10px;
        bottom: 10px;
    };
    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
        background-color: $white-color !important;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        padding: {
            top: 5px;
            bottom: 5px;
        };
    }
    &.navbar-style-two {
        position: relative;

        &.is-sticky {
            position: fixed;
        }
    }
}
.others-option-for-responsive {
    display: none;

    .dot-menu {
        padding: 0 10px;
        height: 30px;
        cursor: pointer;
        z-index: 9991;
        position: absolute;
        right: 60px;
        top: -40px;

        .inner {
            display: flex;
            align-items: center;
            height: 30px;

            .circle {
                height: 5px;
                width: 5px;
                border-radius: 100%;
                margin: 0 2px;
                transition: $transition;
                background-color: $black-color;
            }
        }
        &:hover {
            .inner {
                .circle {
                    background-color: $main-color;
                }
            }
        }
    }
    .container {
        position: relative;

        .container {
            position: absolute;
            right: 0;
            top: 10px;
            max-width: 320px;
            margin-left: auto;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            transform: scaleX(0);
            z-index: 2;
            padding: {
                left: 15px;
                right: 15px;
            };
            &.active {
                opacity: 1;
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }
    .option-inner {
        padding: 15px 15px 8px;
        box-shadow: 0 15px 40px rgba(0,0,0,.09);
        background-color: $white-color;

        .search-box {
            margin-bottom: 15px;
            position: relative;
            border-radius: 5px;

            .input-search {
                display: block;
                width: 100%;
                height: 48px;
                border: none;
                border-radius: 5px;
                background-color: #f5f5f5;
                transition: $transition;
                color: $black-color;
                font: {
                    size: $font-size;
                    weight: 400;
                };
                padding: {
                    left: 15px;
                    top: 1px;
                    bottom: 0;
                    right: 0;
                };
                &::placeholder {
                    color: #848484;
                    transition: $transition;
                }
                &:focus {
                    border-color: $main-color;
    
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                position: absolute;
                right: 0;
                top: 0;
                height: 48px;
                background-color: transparent;
                border: none;
                color: $main-color;
                border-radius: 5px;
                padding: 0 15px;
            }
        }
        .others-option {
            justify-content: center;
            text-align: center;

            .option-item {
                margin-left: 0;

                &:first-child {
                    margin-left: 0;
                }
            }
            .cart-btn {
                font-size: 22px;
                position: relative;
                line-height: 1;

                a {
                    display: inline-block;
                    position: relative;
                    color: $black-color;

                    span {
                        position: absolute;
                        right: -4px;
                        top: -4px;
                        width: 14px;
                        height: 14px;
                        text-align: center;
                        line-height: 14px;
                        border-radius: 50%;
                        color: $white-color;
                        background-color: $main-color;
                        font: {
                            size: 10px;
                            weight: 600;
                        };
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
            }
        }
    }
}

// Navbar toggler 
.navbar-toggler {
    border: none;
    background: #eee !important;
    padding: 10px;
    border-radius: 0;

    &:focus {
        box-shadow: none;
    }

    .icon-bar {
        width: 28px;
        transition: all 0.3s;
        background: $main-color;
        height: 2px;
        display: block;
    }
    .top-bar {
        transform: rotate(45deg);
        transform-origin: 10% 10%;
        left: 4px;
        position: relative;
    }
    .middle-bar {
        opacity: 0;
        margin: 6px 0;
    }
    .bottom-bar {
        transform: rotate(-45deg);
        transform-origin: 10% 90%;
        left: 4px;
        position: relative;
    }
    &.collapsed {
        .top-bar {
            transform: rotate(0);      
            left: 0;
        }
        .middle-bar {
            opacity: 1;
        }
        .bottom-bar {
            transform: rotate(0);
            left: 0;
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 1199px) {
    .navbar-area {
        padding: {
            top: 15px;
            bottom: 15px;
        };
        &.is-sticky {
            padding: {
                top: 10px;
                bottom: 10px;
            };
            .others-option-for-responsive {
                .container {
                    .container {
                        top: 10px;
                    }
                }
            }
        }
        &.navbar-style-two {
            padding: {
                top: 17px;
                bottom: 17px;
            };
        }
    }
    .others-option-for-responsive {
        display: block;
    }
}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(./img/main-banner/main-banner-bg1.png);
        position: bottom center;
        size: cover;
        repeat: no-repeat;
    };
    padding-top: 380px;
    padding-bottom: 360px;
    .container-fluid {
        max-width: 1360px;
    }
    &.without-banner-animation {
        padding: {
            top: 200px;
            bottom: 250px;
        };
        .main-banner-content {
            margin-top: 0;
        }
    }
}
.main-banner-content {
    margin-top: -50px;

    h1 {
        font-size: 57px;
        margin: {
            bottom: 18px;
            top: -10px;
        };
    }
    .btn-box {
        margin-top: 35px;

        .default-btn {
            margin-bottom: -7px;
        }
        .video-btn {
            cursor: pointer;
            position: absolute;
            display: inline-block;
            text-transform: uppercase;
            color: $main-color;
            font: {
                size: 16px;
                weight: 700;
            };
            margin: {
                top: -3px;
                left: 30px;
            };
            i {
                width: 55px;
                height: 55px;
                line-height: 56px;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                background-color: $white-color;
                font-size: 20px;
                margin-right: 5px;
                transition: $transition;
                padding-left: 3px;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.main-banner-animation-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;

        &:nth-child(1) {
            left: 0;
            bottom: -220px;
            z-index: 1;
        }
        &:nth-child(2) {
            left: 192px;
            top: -10px;
            z-index: 1;
        }
        &:nth-child(3) {
            left: -25px;
            top: -200px;
        }
        &:nth-child(4) {
            left: -2px;
            right: 0;
            margin: auto;
            z-index: 1;
            top: -145px;
        }
        &:nth-child(5) {
            left: -10px;
            right: 0;
            top: -250px;
            margin: auto;
            z-index: 1;
        }
        &:nth-child(6) {
            right: 150px;
            top: -50px;
            z-index: 1;
        }
        &:nth-child(7) {
            right: 5px;
            top: -120px;
            z-index: -1;
        }
        &:nth-child(8) {
            left: 0;
            right: 0;
            margin: auto;
            top: -35px;
        }
        &:nth-child(9) {
            right: 0;
            top: -80px;
        }
        &:nth-child(10) {
            right: 0;
            bottom: -190px;
        }
        &:nth-child(11) {
            right: 100px;
            top: -10px;
            z-index: -2;
        }
        &:nth-child(12) {
            left: 140px;
            top: 175px;
            z-index: -1;
        }
        &:last-child {
            display: none;
        }
    }
}
.main-banner-image {
    text-align: center;
}
.banner-section {
    position: relative;
    overflow: hidden;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(./img/main-banner/main-banner-bg2.png);
        position: bottom right;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 365px;
        bottom: 350px;
    };
    .container-fluid {
        max-width: 1360px;
        position: relative;
    }
    &.without-banner-animation {
        padding: {
            top: 200px;
            bottom: 300px;
        };
        .banner-content {
            margin-top: 0;
        }
    }
}
.banner-content {
    margin-top: -50px;

    h1 {
        font-size: 57px;
        margin: {
            bottom: 18px;
            top: -10px;
        };
    }
    .btn-box {
        margin-top: 35px;

        .default-btn {
            margin-bottom: -7px;
        }
        .video-btn {
            cursor: pointer;
            position: absolute;
            display: inline-block;
            text-transform: uppercase;
            color: $main-color;
            font: {
                size: 16px;
                weight: 700;
            };
            margin: {
                top: -3px;
                left: 30px;
            };
            i {
                width: 55px;
                height: 55px;
                line-height: 56px;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                background-color: $white-color;
                font-size: 20px;
                margin-right: 5px;
                transition: $transition;
                padding-left: 3px;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.banner-animation-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;

        &:nth-child(1) {
            top: -180px;
            left: 45px;
        }
        &:nth-child(2) {
            bottom: -80px;
            left: 270px;
        }
        &:nth-child(3) {
            right: 0;
            bottom: 85px;
        }
        &:nth-child(4) {
            right: 0;
            bottom: -90px;
        }
        &:nth-child(5) {
            right: 40px;
            bottom: -15px;
            z-index: 1;
        }
        &:nth-child(6) {
            left: 0;
            bottom: 25px;
        }
        &:nth-child(7) {
            top: -120px;
            left: -50px;
            z-index: 1;
        }
        &:nth-child(8) {
            top: -245px;
            left: 150px;
            z-index: 1;
        }
        &:nth-child(9) {
            left: 200px;
            right: 0;
            margin: auto;
            top: -150px;
        }
        &:nth-child(10) {
            right: 0;
            top: -255px;
        }
        &:nth-child(11) {
            left: 60px;
            bottom: -130px;
        }
        &:nth-child(12) {
            left: 0;
            right: 0;
            margin: auto;
            top: -50px;
            max-width: 70%;
        }
        &:last-child {
            display: none;
        }
    }
}
.banner-image {
    text-align: center;
}
.hero-banner {
    position: relative;
    z-index: 1;
    padding: {
        top: 300px;
        bottom: 300px;
    };
    .container-fluid {
        max-width: 1360px;
    }
    &.without-banner-animation {
        padding: {
            top: 200px;
            bottom: 150px;
        };
    }
}
.hero-banner-content {
    h1 {
        font-size: 59px;
        margin: {
            bottom: 15px;
            top: -10px;
        };
    }
    .btn-box {
        margin-top: 25px;

        .default-btn {
            margin-bottom: -7px;
        }
    }
}
.hero-banner-animation-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;

        &:nth-child(1) {
            left: -23px;
            right: 0;
            margin: auto;
            bottom: -200px;
            z-index: 1;
        }
        &:nth-child(2) {
            right: -55px;
            bottom: -185px;
        }
        &:nth-child(3) {
            left: 161px;
            bottom: -215px;
            z-index: 1;
            max-width: 20%;
        }
        &:nth-child(4) {
            right: 125px;
            top: -140px;
        }
        &:nth-child(5) {
            left: -75px;
            bottom: -175px;
        }
        &:nth-child(6) {
            right: 0;
            left: -110px;
            margin: auto;
            z-index: 1;
            top: -175px;
        }
        &:nth-child(7) {
            left: -100px;
            right: 0;
            margin: auto;
            z-index: 1;
            top: -34px;
        }
        &:nth-child(8) {
            left: 0;
            right: 102px;
            margin: auto;
            top: -5px;
        }
        &:nth-child(9) {
            left: 20px;
            top: -135px;
        }
        &:nth-child(10) {
            left: 0;
            top: -165px;
        }
        &:last-child {
            display: none;
        }
    }
}
.hero-banner-image {
    text-align: center;
}
.main-banner-area {
    overflow: hidden;
    position: relative;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(./img/main-banner/main-banner-bg3.png);
        position: bottom center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 250px;
    };
}
.banner-area-content {
    max-width: 720px;
    text-align: center;
    margin: {
        left: auto;
        right: auto;
    };
    h1 {
        font-size: 65px;
        margin: {
            bottom: 18px;
            top: -10px;
        };
    }
    p {
        max-width: 600px;
        margin: {
            left: auto;
            right: auto;
        };
    }
    .default-btn {
        margin-top: 10px;
    }
    img {
        margin-top: 50px;
    }
}
.main-banner-item {
    height: 800px;
    position: relative;
    z-index: 1;
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        color: $black-color;
    };
}
.banner-item-content {
    max-width: 720px;

    .sub-title {
        display: block;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 800;
        margin-bottom: 25px;
    }
    h1 {
        font-size: 65px;
        color: $white-color;
        margin: {
            bottom: 18px;
            top: -10px;
        };
    }
    p {
        color: $white-color;
        max-width: 650px;
    }
    .btn-box {
        margin-top: 35px;

        .default-btn {
            margin-bottom: -7px;

            span {
                background-color: $white-color;
            }
            &:hover {
                color: $black-color;
            }
        }
        .video-btn {
            cursor: pointer;
            position: absolute;
            display: inline-block;
            text-transform: uppercase;
            color: $main-color;
            font: {
                size: 16px;
                weight: 700;
            };
            margin: {
                top: -3px;
                left: 30px;
            };
            i {
                width: 55px;
                height: 55px;
                line-height: 56px;
                box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
                display: inline-block;
                text-align: center;
                border-radius: 50%;
                background-color: $white-color;
                font-size: 20px;
                margin-right: 5px;
                transition: $transition;
                padding-left: 3px;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.owl-item {
    &.active {
        .banner-item-content {
            .sub-title {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.3s;
                };
            }
            h1 {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.7s;
                };
            }
            p {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 0.9s;
                };
            }
            .btn-box {
                animation: {
                    duration: 1s;
                    fill-mode: both;
                    name: fadeInLeft;
                    delay: 1.1s;
                };
            }
        }
    }
}
.home-slides {
    .swiper-button-prev, .swiper-button-next {
        width: 50px;
        height: 50px; 
        color: $main-color;
        background-color: $white-color;
        transition: $transition;
        border-radius: 4px;
        top: auto;
        bottom: 10px;

        &::after {
            font-size: 22px;
        }
        &:hover {
            background-color: $main-color;
            color: $white-color;
        }
    }
    .swiper-button-prev {
        left: auto;
        right: 68px;
    }
}
.banner-wrapper {
    position: relative;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(./img/main-banner/main-banner-bg4.jpg);
        position: bottom right;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 350px;
        bottom: 250px;
    };
    .container-fluid {
        max-width: 1360px;
    }
    &.without-banner-animation {
        padding: {
            top: 200px;
            bottom: 100px;
        };
        .banner-wrapper-content {
            margin-top: 0;
        }
    }
}
.banner-wrapper-content {
    margin-top: -30px;

    h1 {
        font-size: 49px;
        margin: {
            bottom: 18px;
            top: -10px;
        };
    }
    p {
        max-width: 540px;
    }
    .btn-box {
        margin-top: 35px;

        .default-btn {
            margin-bottom: -7px;

            &:not(:first-child) {
                top: -.3px;
                margin-left: 15px;
                background-color: transparent;
                z-index: 1;
                height: 47px;
                color: $main-color;

                &::before {
                    content: '';
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: -1;
                    border-radius: 5px;
                    bottom: 0;
                    left: 0;
                    border: 1px solid $main-color;
                }
                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}
.banner-wrapper-animation-image {
    position: relative;
    width: 100%;
    height: 100%;

    img {
        position: absolute;
        max-width: 39%;

        &:nth-child(1) {
            right: 30px;
            bottom: -60px;
            z-index: 2;
        }
        &:nth-child(2) {
            right: 2px;
            bottom: -107px;
            z-index: 1;
        }
        &:nth-child(3) {
            right: 75px;
            top: -210px;
            z-index: 1;
        }
        &:nth-child(4) {
            right: 0;
            top: -180px;
        }
        &:nth-child(5) {
            left: 80px;
            top: -165px;
        }
        &:nth-child(6) {
            right: 0;
            left: 220px;
            margin: auto;
            top: 150px;
            z-index: 2;
        }
        &:nth-child(7) {
            left: 188px;
            bottom: -140px;
            z-index: 2;
        }
        &:nth-child(8) {
            left: -25px;
            bottom: -40px;
            z-index: 1;
        }
        &:nth-child(9) {
            right: 0;
            left: 30px;
            margin: auto;
            top: -70px;
            max-width: 82%;
        }
        &:nth-child(10) {
            left: 0;
            right: -70px;
            top: -100px;
            margin: auto;
            max-width: 82%;
            z-index: -1;
        }
        &:last-child {
            display: none;
        }
    }
}
.banner-wrapper-image {
    text-align: center;
}
.vector-shape7 {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
}
.vector-shape8 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}
.it-services-banner {
    position: relative;
    z-index: 1;
    background: {
        color: $white-color;
        image: url(./img/banner-bg.png);
        position: bottom center;
        size: cover;
        repeat: no-repeat;
    };
    padding: {
        top: 220px;
        bottom: 210px;
    };
    .main-banner-content {
        margin-top: 0;
    }
}
.seo-agency-banner {
    position: relative;
    z-index: 1;
    background: transparent url(./img/banner-bg2.jpg) left top no-repeat;
    padding: {
        top: 270px;
        bottom: 150px;
    };
    border-bottom: 1px solid #f7f7f7;
    .container-fluid {
        max-width: 1360px;
    }
    .banner-wrapper-content {
        margin-top: 0;

        h1 {
            margin-bottom: 20px;
        }
        .btn-box {
            margin-top: 25px;
        }
    }
}
.banner-wrapper-content {
    .sub-title {
        display: block;
        margin-bottom: 20px;
        color: $main-color;
        text-transform: uppercase;
        font-weight: 600;
    }
}
.digital-agency-banner {
    background:linear-gradient(75deg, #65258a 10%, #e80566) !important;
    position: relative;
    z-index: 1;
    padding: {
        top: 120px;
        bottom: 120px;
    };
    .banner-wrapper-content {
        margin-top: 0;

        .sub-title {
            color: $white-color;
        }
        h1 {
            color: $white-color;
        }
        p {
            color: $white-color;
        }
    }
}

/*================================================
Solutions Area CSS
=================================================*/
.solutions-area {
    .section-title {
        max-width: 695px;
    }
}
.single-solutions-box {
    margin-bottom: 30px;
    padding: 50px 30px;
    border-radius: 5px;
    transition: $transition;
    border: 1px solid #f5f3f2;
    text-align: center;

    .icon {
        margin-bottom: 25px;
        line-height: 1;
        font-size: 65px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
        };
        a {
            display: inline-block;
        }
    }
    .view-details-btn {
        display: inline-block;
        color: $main-color;
        margin-top: 3px;
        position: relative;
        font: {
            size: 16.4px;
            weight: 700;
        };
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 1px;
            background-color: $main-color;
            transition: $transition;
        }
        &:hover {
            color: $black-color;

            &::before {
                width: 100%;
            }
        }
    }
    &:hover {
        border-color: $main-color;

        .icon {
            animation: bounce 2s;
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    position: relative;
    z-index: 1;

    .container-fluid {
        max-width: 1920px;
    }
}
.our-mission-area {
    overflow: hidden;
    .container-fluid {
        max-width: 1920px;
    }
}
.about-content {
    .content {
        max-width: 585px;

        .sub-title {
            position: relative;
            display: block;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: $main-color;
            font: {
                weight: 700;
                size: 15px;
            };
            img {
                margin-right: 5px;
                animation: {
                    name: rotateme;
                    duration: 40s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
        }
        h2 {
            margin-bottom: 15px;
            font: {
                size: 44px;
            };
        }
        p {
            margin-bottom: 20px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            display: block;
            margin: {
                bottom: 25px;
                top: -20px;
                left: -15px;
                right: -15px;
            };
            overflow: hidden;
            li {
                width: 45%;
                margin: {
                    left: 15px;
                    right: 15px;
                    top: 30px;
                };
                float: left;
           
                display: block;
                border: 1px solid #f0eae2;
                border-radius: 5px;
                padding: 20px 20px 20px 95px;
                position: relative;
                background-color: $white-color;
                transition: $transition;

                img {
                    position: absolute;
                    left: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                h3 {
                    margin-bottom: 3px;
                    font: {
                        size: 25px;
                    };
                }
                &:hover {
                    border-color: $main-color;
                }
            }
        }
        .about-list {
            padding-left: 0;
            padding-bottom: 7px;
            list-style-type: none;
            overflow: hidden;
            margin: {
                bottom: 30px;
                top: -20px;
                left: -15px;
                right: -15px;
            };
            li {
                width: 45%;
                float: left;
                margin: {
                    left: 15px;
                    right: 15px;
                };
                margin-top: 30px;
                position: relative;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                padding: 20px 15px 20px 68px;
                border-radius: 5px;
                z-index: 1;
                background-color: $white-color;

                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: $transition;
                    background-color: #deecf4;
                    font-size: 20px;
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
        .content-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 30px;
                top: 30px;
            };
            li {
                position: relative;
                margin-bottom: 35px;
                padding-left: 53px;
                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: $transition;
                    background-color: #deecf4;
                    font-size: 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
        .default-btn {
            margin-top: 5px;
        }
    }
}
.about-image {
    text-align: center;
}
.about-image-box {
    text-align: center;
    position: relative;
    margin-right: 20px;
    border-radius: 5px;
    padding: {
        bottom: 15px;
        right: 15px;
    };
    img {
        border-radius: 5px;
    }
    &::before {
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        border-radius: 5px;
        position: absolute;
        background: $main-color;
        z-index: -1;
        margin: {
            top: 15px;
            left: 15px;
        };
    }
    .box {
        background-color: $white-color;
        left: 0;
        bottom: 15px;
        position: absolute;
        border-radius: 0 5px 0 0;
        padding: 10px 20px;
    }
    .video-btn {
        cursor: pointer;
        display: inline-block;
        text-transform: uppercase;
        color: $main-color;
        font: {
            size: 16px;
            weight: 700;
        };
        i {
            width: 55px;
            height: 55px;
            line-height: 56px;
            box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .10);
            display: inline-block;
            text-align: center;
            border-radius: 50%;
            background-color: $white-color;
            font-size: 20px;
            margin-right: 5px;
            transition: $transition;
        }
        &:hover {
            i {
                background-color: $main-color;
                color: $white-color;
            }
        }
    }
}
.about-img {
    text-align: center;
    position: relative;
    z-index: 1;
 
    .shape {
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}
.about-inner-area {
    margin-top: 100px;
}
.about-text {
    h3 {
        margin-bottom: 13px;
        font: {
            size: 25px;
        }
    }
    .features-list {
        padding-left: 0;
        list-style-type: none;
        margin: {
            top: 15px;
            bottom: 0;
        }
        li {
            position: relative;
            margin-bottom: 12px;
            color: $optional-color;
            font-weight: 600;
            padding: {
                top: 5px;
                left: 30px;
            };
            i {
                font-size: 9px;
                color: $black-color;
                background-color: #eaeaf2;;
                width: 20px;
                height: 20px;
                line-height: 20px;
                border-radius: 50%;
                transition: $transition;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            &:last-child {
                margin-bottom: 0;
            }
            &:hover {
                i {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
}
.circle-shape1 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
}
@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/*================================================
Our Mission Area CSS
=================================================*/
.our-mission-content {
    position: relative;
    .content {
        max-width: 585px;
        margin-left: auto;

        .sub-title {
            position: relative;
            display: block;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: $main-color;
            font: {
                weight: 700;
                size: 15px;
            };
            img {
                margin-right: 5px;
                animation: {
                    name: rotateme;
                    duration: 40s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
        }
        h2 {
            margin-bottom: 15px;
            font: {
                size: 44px;
            };
        }
        p {
            margin-bottom: 20px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
        .our-mission-list {
            padding-left: 0;
            list-style-type: none;
            overflow: hidden;
            padding-bottom: 7px;
            margin: {
                bottom: 30px;
                top: -20px;
                left: -15px;
                right: -15px;
            };
            li {
                width: 45%;
                float: left;
                margin: {
                    left: 15px;
                    right: 15px;
                    top: 30px;
                };
                position: relative;
                box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
                padding: 20px 15px 20px 68px;
                border-radius: 5px;
                z-index: 1;
                background-color: $white-color;
                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: $transition;
                    background-color: #deecf4;
                    font-size: 20px;
                }
                img {
                    position: absolute;
                    right: 0;
                    bottom: 0;
                    z-index: -1;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                bottom: 30px;
                top: 30px;
            };
            li {
                position: relative;
                margin-bottom: 35px;
                padding-left: 53px;
                font: {
                    size: 19px;
                    weight: 800;
                };
                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 3px;
                    width: 40px;
                    height: 40px;
                    line-height: 41px;
                    text-align: center;
                    transition: $transition;
                    background-color: #deecf4;
                    font-size: 20px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    i {
                        background-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
        .default-btn {
            margin-top: 5px;
        }
    }
}
.our-mission-image {
    text-align: center;
    position: relative;
    z-index: 1;

    .shape {
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}

/*================================================
Boxes Area CSS
=================================================*/
.boxes-area {
    position: relative;
    z-index: 1;
    margin-top: -160px;
}
.single-boxes-item {
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: $white-color;
    border-radius: 5px;
    padding: 40px 30px 35px;
    transition: $transition;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);

    .icon {
        margin-bottom: 30px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .default-btn {
        background-color: transparent;
        color: $main-color;
        border: 1px solid #eae1d6;
        margin-top: 8px;

        span {
            display: none;
        }
        i {
            transition: $transition;
        }
        &:hover {
            color: $white-color;
            border-color: $main-color;
            background-color: $main-color;
            padding: 12px 50px 12px 25px;

            i {
                left: 73%;
            }
        }
    }
    .shape1 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        transition: $transition;
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.09);
    }
}
.col-lg-4 {
    &:nth-child(2) {
        .single-boxes-item {
            .shape1 {
                bottom: auto;
                top: 0;
                transform: rotate(-90deg);
                right: -5px;
            }
        }
    }
}
.vector-shape9 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.vector-shape10 {
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
}

/*================================================
Funfacts Area CSS
=================================================*/
.funfacts-area {
    position: relative;
    z-index: 1;

    &.bg-image {
        background: {
            color: $black-color;
            position: center center;
            size: cover;
            repeat: no-repeat;
            image: url(./img/funfacts/funfacts-bg.jpg);
        };
        .single-funfacts-item {
            h3 {
                color: $white-color;
            }
            &::before {
                background-color: #402939;
            }
            p {
                color: #a6a6b4;
            }
        }
    }
}
.single-funfacts-box {
    margin-bottom: 30px;
    border: 1px solid #f0eae2;
    border-radius: 5px;
    padding: 20px 20px 20px 98px;
    position: relative;
    background-color: $white-color;
    transition: $transition;

    .icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    h3 {
        margin-bottom: 3px;
        font: {
            size: 28px;
        };
    }
    &:hover {
        border-color: $main-color;
    }
}
.single-funfacts-item {
    margin-bottom: 30px;
    padding-left: 98px;
    position: relative;

    .icon {
        position: absolute;
        left: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    h3 {
        margin-bottom: 3px;
        font: {
            size: 28px;
        };
    }
    &::before {
        width: 1px;
        height: 100%;
        content: '';
        position: absolute;
        right: 10px;
        top: 0;
        background-color: #eacbae;
        transform: rotate(25deg);
    }
}
.col-lg-3 {
    &:last-child {
        .single-funfacts-item {
            &::before {
                display: none;
            }
        }
    }
}

/*================================================
What We Do Area CSS
=================================================*/
.single-what-we-do-box {
    margin-bottom: 30px;
    position: relative;
    padding-left: 105px;

    .icon {
        position: absolute;
        left: 0;
        top: 0;
        background-color: #f4f4f4;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        color: $main-color;
        text-align: center;
        font-size: 55px;
        transition: $transition;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h3 {
        margin-bottom: 10px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    p {
        margin-bottom: 12px;
    }
    .link {
        display: block;
        font-weight: 700;
        text-decoration: underline;
        margin-bottom: 7px;

        i {
            color: $optional-color;
        }
        &:hover {
            color: $main-color;
        }
    }
    .read-more-btn {
        color: $main-color;
        font-weight: 700;
        display: inline-block;
        position: relative;
        padding-left: 23px;
        margin-top: 15px;

        i {
            position: absolute;
            left: 0;
            top: 3px;
            transition: $transition;
            line-height: 1;
        }
        &:hover {
            color: $black-color;
            padding: {
                left: 0;
                right: 8px;
            };
            i {
                left: 100%;
                top: 4.5px;
            }
        }
    }
    &:hover {
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
.col-lg-4 {
    &:nth-child(1) {
        .single-what-we-do-box {
            margin-top: 60px;
        }
    }
    &:nth-child(2) {
        .single-what-we-do-box {
            margin-top: 30px;
        }
    }
}

/*================================================
History Area CSS
=================================================*/
.history-timeline {
    position: relative;
    list-style-type: none;
    padding-left: 0;
    z-index: 1;
    margin: {
        left: auto;
        right: auto;
        bottom: 0;
    };
    &::before {
        content: '';
        width: 1px;
        height: 100%;
        background-color: #ebebeb;
        top: 0;
        left: 130px;
        position: absolute;
    }
    .timeline-block {
        position: relative;
        padding-left: 165px;
        margin-bottom: 30px;

        &:last-child {
            margin-bottom: 0;

            &::before {
                display: none;
            }
        }
        &::before {
            content: "\ea17";
            position: absolute;
            left: 116px;
            color: $main-color;
            bottom: -25px;
            animation: fade-down 1.5s infinite linear;
            font: {
                family: "boxicons" !important;
                size: 30px;
            };
        }
        .timeline-date {
            text-align: right;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            color: $optional-color;
            font: {
                size: 15px;
            };
            span {
                display: block;
                margin-bottom: 3px;
                color: $black-color;
                font-weight: 700;
            }
            sup {
                color: $black-color;
                font-weight: 600;
            }
        }
        .timeline-icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 126px;

            .dot-badge {
                background-color: $main-color;
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 50%;
            }
        }
        .timeline-content {
            background-color: $white-color;
            padding: 30px;

            .content {
                padding: {
                    left: 5px;
                    right: 15px;
                };
                h3 {
                    margin-bottom: 15px;
                    font-size: 25px;
                }
            }
        }
    }
    &.history-timeline-style-two {
        &::before {
            left: auto;
            right: 130px;
            background-color: #f3f1f1;
        }
        .timeline-block {
            padding: {
                left: 0;
                right: 165px;
            };
            &::before {
                left: auto;
                right: 115px;
            }
            .timeline-date {
                text-align: left;
                right: 0;
                left: auto;
            }
            .timeline-icon {
                left: auto;
                right: 125px;
            }
            .timeline-content {
                background-color: #f7faff;
                
                .content {
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                }
            }
        }
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    position: relative;
    z-index: 1;
}
.single-services-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 45px 30px 80px;
    position: relative;
    border-radius: 5px;
    z-index: 1;
    overflow: hidden;

    .icon {
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    &::before {
        content: '';
        position: absolute;
        bottom: -88%;
        left: 0;
        width: 115%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: linear-gradient(to right top, #66eaff, #8feffd, #b0f3fd, #ccf7fc, #e7fbfd);
        transform: rotate(-12deg);
    }
    &::after {
        content: '';
        position: absolute;
        bottom: -88%;
        left: 0;
        width: 115%;
        height: 100%;
        z-index: -1;
        transition: $transition;
        background: linear-gradient(to right top, #ff8008, #ff8a0d, #ff9413, #ff9d1a, #ffa621);
        transform: rotate(-12deg);
        opacity: 0;
        visibility: hidden;
    }
    .read-more-btn {
        display: inline-block;
        color: $main-color;
        position: absolute;
        right: 45px;
        bottom: 15px;
        font: {
            size: $font-size;
            weight: 700;
        };
        i {
            position: relative;
            top: 2px;
        }
    }
    &:hover {
        &::before {
            opacity: 0;
            visibility: hidden;
        }
        &::after {
            opacity: 1;
            visibility: visible;
        }
        .read-more-btn {
            color: $white-color;
        }
    }
}
.single-services-item {
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: $white-color;
    border-radius: 5px;
    padding: 40px 30px 35px;
    transition: $transition;

    .icon {
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .default-btn {
        background-color: transparent;
        color: $main-color;
        border: 1px solid #eae1d6;
        margin-top: 8px;

        span {
            display: none;
        }
        i {
            transition: $transition;
        }
        &:hover {
            color: $white-color;
            border-color: $main-color;
            background-color: $main-color;
            padding: 12px 50px 12px 25px;

            i {
                left: 73%;
            }
        }
    }
    .shape1 {
        position: absolute;
        left: 0;
        right: 0;
        z-index: -1;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0.85;
    }
    .shape2 {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
        transition: $transition;
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);

        .shape1 {
            img {
                animation: {
                    name: rotateme;
                    duration: 50s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
        }
        .shape2 {
            right: -10px;
            bottom: -10px;
        }
    }
}
.single-services-item-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: $white-color;
    padding: 45px 30px;
    border-radius: 5px;

    .icon {
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .read-more-btn {
        color: $main-color;
        font-weight: 700;
        display: inline-block;
        position: relative;
        padding-right: 23px;

        i {
            position: absolute;
            right: 0;
            top: 4px;
            transition: $transition;
            line-height: 1;
        }
        &:hover {
            color: $black-color;
            padding: {
                right: 0;
                left: 8px;
            };
            i {
                right: 100%;
            }
        }
    }
}
.single-services-box-item {
    background-color: #fafafb;
    position: relative;
    z-index: 1;
    overflow: hidden;
    padding: 90px 35px 70px;
    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);

    .icon {
        width: 90px;
        height: 90px;
        text-align: center;
        position: relative;
        background-color: #80c54d;
        border-radius: 5px;
        text-align: center;
        margin-bottom: 30px;

        img {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            margin: auto;
        }
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .shape {
        position: absolute;
        right: 0;
        top: -15px;
        z-index: -1;
    }
    .learn-more-btn {
        color: $main-color;
        font-weight: 700;
        display: inline-block;
        position: relative;
        padding-left: 23px;

        i {
            position: absolute;
            left: 0;
            top: 3px;
            transition: $transition;
            line-height: 1;
        }
        &:hover {
            color: $black-color;
            padding: {
                left: 0;
                right: 8px;
            };
            i {
                left: 100%;
                top: 4.5px;
            }
        }
    }
}
.col-lg-4 {
    &:nth-child(2) {
        .single-services-box-item {
            .icon {
                background-color: #4267d1;
            }
        }
    }
    &:nth-child(3) {
        .single-services-box-item {
            .icon {
                background-color: #ca8220;
            }
        }
    }
    &:nth-child(4) {
        .single-services-box-item {
            .icon {
                background-color: #b92fb1;
            }
        }
    }
    &:nth-child(5) {
        .single-services-box-item {
            .icon {
                background-color: #de6742;
            }
        }
    }
    &:nth-child(6) {
        .single-services-box-item {
            .icon {
                background-color: #59eed0;
            }
        }
    }
}
.circle-shape2 {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
}
.load-more-btn {
    margin-top: 25px;

    .default-btn {
        background-color: transparent;
        color: $main-color;
        border: 1px solid #eae1d6;
        margin-bottom: -7px;

        span {
            background-color: $main-color;
        }
        &:hover {
            color: $white-color;
            border-color: $main-color;
        }
    }
}
.lines {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    margin: auto;
    width: 100%;
    z-index: -1;

    .line {
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0;
        left: 50%;
        background: #e5ebf0;
        overflow: hidden;

        &::after {
            content: "";
            display: block;
            position: absolute;
            height: 15vh;
            width: 100%;
            top: -50%;
            left: 0;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, $main-color 75%, $main-color 100%);
            animation: run 7s 0s infinite;
            animation-fill-mode: forwards;
            animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
        }
        &:nth-child(1) {
            margin-left: -30%;

            &::after {
                animation-delay: 2s;
            }
        }
        &:nth-child(3) {
            margin-left: 30%;

            &::after {
                animation-delay: 2.5s;
            }
        }
        &:nth-child(4) {
            margin-left: -15%;

            &::after {
                animation-delay: 3s;
            }
        }
        &:nth-child(5) {
            margin-left: 15%;

            &::after {
                animation-delay: 3.5s;
            }
        }
    }
}
@keyframes run {
    0% {
        top: -50%;
    }
    100% {
        top: 110%;
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-image {
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;

    img {
        border-radius: 5px;
        width: 100%;
    }
}
.services-details-image-slides {
    .swiper-button-next, .swiper-button-prev {
        color: #87a1c1;
        background: $white-color;
        width: 45px;
        height: 45px; 
        transition: $transition;
        border: {
            radius: 50%;
            style: solid;
            color: $white-color;
            width: 1.5px;
        };

        &::after {
            font-size: 18px;
        }
 
        &:hover {
            background-color: $main-color;
            color: $white-color;
            border-color: $main-color;
        }
    }
}
.services-details-desc {
    .sub-title {
        color: $main-color;
        display: block;
        margin-bottom: -18px;
        font: {
            size: 16px;
            weight: 700;
        };
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 30px;
        };
        font: {
            size: 28px;
        };
    }
    .image {
        padding-right: 15px;
        border-radius: 5px;
        margin: {
            top: 15px;
            bottom: 30px;
        };
        img {
            border-radius: 5px;
        }
    }
    .content {
        margin: {
            top: 15px;
            bottom: 30px;
        };
        h3 {
            font-size: 24px;
            margin-bottom: 18px;
            margin: {
                top: 0;
            };
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 12px;
                font-weight: 600;
                color: $optional-color;
                position: relative;
                padding-left: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $main-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .technologies-features {
        padding-left: 0;
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin: {
            bottom: 25px;
            top: -5px;
        };
        li {
            flex: 0 0 33.333333%;
            max-width: 33.333333%;
            padding-top: 12px;

            span {
                font-weight: 600;
                color: $optional-color;
                position: relative;
                display: block;
                padding-left: 19px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 45%;
                    transform: translateY(-45%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $main-color;
                }
            }
        }
    }
    .single-industries-serve-box {
        font-size: 22px;
        margin: {
            top: 15px;
            bottom: 20px;
        };
    }
}
.services-details-info {
    padding-left: 15px;

    .services-list {
        padding-left: 0;
        list-style-type: none;
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;

        li {
            a {
                display: block;
                border-bottom: 1px solid #eeeeee;
                padding: 20px 30px;
                position: relative;
                font: {
                    size: $font-size;
                    weight: 800;
                };
                &::before {
                    content: "\f104";
                    position: absolute;
                    right: 30px;
                    top: 50%;
                    transform: translateY(-50%);
                    font: {
                        family: Flaticon;
                        size: 18px;
                    };
                }
                &:hover, &.active {
                    background-color: $main-color;
                    border-color: $main-color;
                    color: $white-color;
                }
            }
            &:last-child {
                a {
                    border-bottom: none;
                }
            }
        }
    }
    .download-file {
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;
        padding: 30px;

        h3 {
            margin-bottom: 25px;
            font: {
                size: 24px;
            };
        }
        ul {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;
    
            li {
                a {
                    display: block;
                    border-bottom: 1px solid #eeeeee;
                    position: relative;
                    padding: {
                        top: 15px;
                        bottom: 15px;
                    };
                    font: {
                        size: 16.5px;
                        weight: 700;
                    };
                    i {
                        position: absolute;
                        right: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                &:first-child {
                    border-top: 1px solid #eeeeee;
                }
            }
        }
    }
    .services-contact-info {
        background-color: $white-color;
        box-shadow: 0 5px 40px 10px #eaeaef;
        margin-bottom: 30px;
        padding: 30px;

        h3 {
            margin-bottom: 25px;
            font: {
                size: 24px;
            };
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
    
            li {
                margin-bottom: 20px;
                border-bottom: 1px solid #eeeeee;
                position: relative;
                color: $optional-color;
                font: {
                    weight: 600;
                    size: 16.5px;
                };
                padding: {
                    left: 35px;
                    bottom: 20px;
                };
                span {
                    color: $black-color;
                    display: block;
                    margin-bottom: 8px;
                    font: {
                        size: 20px;
                        weight: 800;
                    };
                }
                a {
                    display: block;
                    color: $optional-color;
                    margin-bottom: 8px;
                    
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:hover {
                        color: $main-color;
                    }
                }
                .icon {
                    position: absolute;
                    left: 0;
                    top: 3px;
                    color: $main-color;
                    font: {
                        weight: normal;
                        size: 25px;
                    };
                }
                &:last-child {
                    padding-bottom: 0;
                    margin-bottom: 0;
                    border-bottom: none;
                }
            }
        }
    }
}

/*================================================
Featured Services Area CSS
=================================================*/
.single-featured-services-item {
    margin-bottom: 30px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    background-color: $white-color;
    border-radius: 5px;
    padding: 60px 30px 55px;
    transition: $transition;
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

    .icon {
        margin-bottom: 30px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .default-btn {
        background-color: transparent;
        color: $main-color;
        border: 1px solid #eae1d6;
        margin-top: 8px;

        span {
            background-color: $main-color;
            display: none;
        }
        i {
            transition: $transition;
        }
        &:hover {
            color: $white-color;
            background-color: $main-color;
            border-color: $main-color;
            padding: 12px 50px 12px 25px;

            i {
                left: 73%;
            }
        }
    }
    .shape {
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: -1;
        border-radius: 5px;
        opacity: .50;

        img {
            border-radius: 5px;
        }
    }
    &:hover {
        .icon {
            animation: bounce 2s;
        }
    }
}
.single-featured-services-box {
    margin-bottom: 30px;
    position: relative;
    padding-right: 15px;

    .icon {
        background-color: $main-color;
        color: $white-color;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        text-align: center;
        font-size: 45px;
        margin-bottom: 30px;
        position: relative;
        transition: $transition;

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        a {
            display: inline-block;
        }
    }
    .read-more-btn {
        color: $main-color;
        font-weight: 700;
        display: inline-block;
        position: relative;
        padding-left: 23px;

        i {
            position: absolute;
            left: 0;
            top: 3px;
            transition: $transition;
            line-height: 1;
        }
        &:hover {
            color: $black-color;
            padding: {
                left: 0;
                right: 8px;
            };
            i {
                left: 100%;
                top: 4.5px;
            }
        }
    }
    &:hover {
        .icon {
            background-color: $black-color !important;
            color: $white-color !important;
        }
    }
}
.col-lg-4 {
    &:nth-child(2) {
        .single-featured-services-item {
            .shape {
                transform: scaleY(-1);
                bottom: auto;
                top: 0;
            }
        }
        .single-featured-services-box {
            margin-top: 30px;

            .icon {
                background-color: #01b882;
                color: $white-color;
            }
        }
    }
    &:nth-child(3) {
        .single-featured-services-box {
            .icon {
                background-color: #bb005c;
                color: $white-color;
            }
        }
    }
    &:nth-child(1) {
        .single-featured-services-box {
            margin-top: 60px;
        }
    }
}

/*================================================
How It's Work Area CSS
=================================================*/
.process-area {
    position: relative;
    z-index: 1;
}
.single-process-box {
    margin-bottom: 30px;
    background-color: $white-color;
    transition: $transition;
    border: 1px solid #f5f3f2;
    border-radius: 5px;
    padding: 50px 20px;
    text-align: center;
    position: relative;
    z-index: 1;

    .image {
        margin-bottom: 25px;
    }
    h3 {
        margin-bottom: 12px;
        font: {
            size: 24px;
        };
        a {
            display: inline-block;
        }
    }
    .number {
        line-height: 1;
        color: #eeedef;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 10px;
        z-index: -1;
        opacity: .45;
        transition: $transition;
        font: {
            size: 215px;
            weight: 800;
            style: italic;
        };
    }
    &:hover {
        border-color: $main-color;

        .number {
            transform: translateX(10px) rotate(5deg);
        }
    }
}
.process-image {
    text-align: center;
}
.process-content {
    .single-box-item {
        margin-bottom: 20px;
        background-color: $white-color;
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .02);
        border-radius: 5px;
        position: relative;
        overflow: hidden;
        z-index: 1;
        max-width: 430px;

        .image {
            background-color: #edfcff;
            padding: 15px 20px;
            border-radius: 5px 0 0 5px;
        }
        h3 {
            margin-bottom: 0;
            font-size: 24px;
            padding: 10px 20px 10px 20px;
        }
        .number {
            color: #eeedef;
            line-height: 1;
            position: absolute;
            right: 5px;
            bottom: -45px;
            z-index: -1;
            font: {
                size: 105px;
                style: italic;
                weight: 700;
            };
        }
        &:hover {
            .number {
                animation: bounce 2s;
            }
        }
    }
    .row {
        .col-lg-12 {
            &:nth-child(1) {
                .single-box-item {
                    margin-left: -30px;
                }
            }
            &:nth-child(2) {
                .single-box-item {
                    margin-left: 10px;
                }
            }
            &:nth-child(3) {
                .single-box-item {
                    margin-left: 50px;
                }
            }
            &:nth-child(4) {
                .single-box-item {
                    margin-left: 90px;
                }
            }
            &:nth-child(5) {
                .single-box-item {
                    margin-left: 130px;
                }
            }
            &:nth-child(6) {
                .single-box-item {
                    margin-left: 170px;
                }
            }
            &:last-child {
                .single-box-item {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.how-its-work-content {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 44px;
        };
    }
    .inner-box {
        margin-top: 35px;

        .single-item {
            position: relative;
            z-index: 1;
            padding: {
                left: 100px;
                bottom: 30px;
            };
            .count-box {
                width: 65px;
                height: 65px;
                position: absolute;
                left: 8px;
                top: 8px;
                line-height: 65px;
                text-align: center;
                color: $main-color;
                border-radius: 50%;
                background-color: $white-color;
                box-shadow: rgb(237, 241, 254) 0px 0px 0px 7px;
                font: {
                    size: 30px;
                    weight: 800;
                    style: italic;
                };
            }
            h3 {
                margin-bottom: 10px;
                font: {
                    size: 24px;
                };
            }
            &::before {
                content: '';
                position: absolute;
                top: 1px;
                width: 1px;
                height: 100%;
                background-color: $main-color;
                z-index: -1;
                left: 40px;
            }
            &::after {
                content: "\ea17";
                position: absolute;
                left: 26px;
                color: $main-color;
                bottom: 10px;
                animation: fade-down 1.5s infinite linear;
                font: {
                    family: 'boxicons'!important;
                    size: 30px;
                };
            }
            &:last-child {
                padding-bottom: 0;

                &::before {
                    display: none;
                }
                &::after {
                    display: none;
                }
            }
        }
    }
}

/*================================================
Courses Area CSS
=================================================*/
.courses-area {
    position: relative;
    z-index: 1;

    .vector-shape6 {
        bottom: 7%;
    }
}
.single-courses-box {
    margin-bottom: 30px;
    position: relative;
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);

    .courses-image {
        border-radius: 5px 5px 0 0;
        position: relative;
        z-index: 1;

        .image {
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
            }
        }
        .fav {
            display: inline-block;
            position: absolute;
            right: 20px;
            top: 20px;
            color: #ffffff;
            font-size: 30px;
            line-height: 1;
            border: none;
            padding: 0;
            background: transparent;

            &:hover, &.favorited {
                color: $main-color;
            }
        }
        .price {
            display: inline-block;
            background-color: $main-color;
            color: $white-color;
            width: 65px;
            height: 65px;
            border-radius: 50%;
            position: absolute;
            right: 20px;
            bottom: -32.5px;
            text-align: center;
            line-height: 67px;
            font: {
                size: 22px;
                weight: 800;
            };
            &.free {
                background-color: green;
            }
        }
    }
    .courses-content {
        border-radius: 0 0 5px 5px;
        padding: 30px;

        .course-author {
            margin-bottom: 15px;

            img {
                width: 35px;
                height: 35px;
                margin-right: 10px;
            }
            span {
                color: $main-color;
                font: {
                    weight: 600;
                    size: 15px;
                };
            }
        }
        h3 {
            margin-bottom: 12px;
            line-height: 1.3;
            font: {
                size: 25px;
            };
            a {
                display: inline-block;
            }
        }
        .courses-box-footer {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: 0;
                left: -7px;
                right: -7px;
                top: 20px;
            };
            li {
                color: $optional-color;
                font-size: 15px;
                position: relative;
                padding: {
                    left: 28px;
                    right: 7px;
                };
                i {
                    color: $main-color;
                    position: absolute;
                    left: 7px;
                    top: 0;

                    &.flaticon-team {
                        font-size: 18px;
                        left: 3px;
                        top: -3px;
                    }
                }
            }
        }
    }
}
.courses-info {
    text-align: center;
    margin-top: 20px;

    p {
        max-width: 600px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
        a {
            color: $main-color;

            &:hover {
                text-decoration: underline;
                color: $black-color;
            }
        }
    }
}

/*================================================
Courses Details Area CSS
=================================================*/
.courses-details-desc {
    margin-top: 60px;
    padding-right: 15px;

    .react-tabs__tab-list {
        margin-bottom: 0;
        list-style-type: none;
        background-color: #f8f9f8;
        border: none;
        border-radius: 5px;
        padding: {
            left: 30px;
            right: 30px;
        };
        li { 
            margin: {
                bottom: 0;
                right: 40px;
            };
          
            border-radius: 0;
            border: none;
            padding: 0;
            transition: $transition;
            padding: 14px 0;
            background-color: #f8f9f8;
            position: relative;
            border-radius: 5px;
            font: {
                size: 18px;
                weight: 800;
            };
            &::before {
                content: '';
                bottom: 0;
                height: 2px;
                background-color: $main-color;
                position: absolute;
                transition: $transition;
                left: 0;
                width: 100%;
                transform: scaleX(0);
            }
            &:hover, &.react-tabs__tab--selected {
                color: $black-color;

                &::before {
                    transform: scaleX(1);
                }
            }
            &:last-child {
                margin-right: 0;
            } 
        }
    }
    .react-tabs__tab-panel {
        padding: {
            left: 30px;
            right: 30px;
            top: 40px;
        };
        .courses-overview {
            h3 {
                margin-bottom: 12px;
                font: {
                    size: 25px;
                };
                &:not(:first-child) {
                    margin-top: 30px;
                }
            }
        }
        .courses-curriculum {
            border: 1px solid #eeeeee;
            border-radius: 5px;
            padding: 30px;
            margin: {
                left: -30px;
                right: -30px;
            };
            h3 {
                margin-bottom: 18px;
                font: {
                    size: 20px;
                };
                &:not(:first-child) {
                    margin-top: 30px;
                }
            }
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: block;

                    a {
                        background-color: #f8f9f8;
                        color: $black-color;
                        position: relative;
                        padding: {
                            left: 55px;
                            right: 30px;
                            top: 14.5px;
                            bottom: 14px;
                        };
                        margin: {
                            left: -30px;
                            right: -30px;
                        };
                        &::before {
                            content: "\f12c";
                            position: absolute;
                            left: 30px;
                            color: $main-color;
                            top: 14px;
                            font: {
                                size: 18px;
                                weight: normal;
                                family: Flaticon;
                            };
                        }
                        .courses-name {
                            font: {
                                size: 15px;
                                weight: 800;
                            };
                        }
                        .courses-meta {
                            text-align: right;

                            .questions {
                                display: inline-block;
                                background: #e3f1f2;
                                color: #2dbbc4;
                                text-transform: lowercase;
                                border-radius: 3px;
                                margin-right: 6px;
                                padding: 2px 10px 1.5px;
                                font: {
                                    size: 14px;
                                    weight: 700;
                                };
                            }
                            .duration {
                                display: inline-block;
                                background: #f7e7e8;
                                color: $main-color;
                                text-transform: lowercase;
                                border-radius: 3px;
                                padding: 2px 10px 1.5px;
                                font: {
                                    size: 14px;
                                    weight: 700;
                                };
                            }
                            .status {
                                display: inline-block;
                                background: $black-color;
                                color: $white-color;
                                border-radius: 3px;
                                margin-left: 6px;
                                padding: 2px 10px 1.5px;
                                font: {
                                    size: 14px;
                                    weight: 700;
                                };
                                &.locked {
                                    color: $black-color;
                                    background-color: transparent;
                                    padding: 0;
                                    margin-left: 8px;
                                    border-radius: 0;
                                    position: relative;
                                    top: 1px;
                                    font: {
                                        size: 18px;
                                        weight: normal;
                                    };
                                }
                            }
                        }
                        &:hover {
                            color: $main-color;
                        }
                    }
                    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10), &:nth-child(12), &:nth-child(14), &:nth-child(16), &:nth-child(18), &:nth-child(20), &:nth-child(22), &:nth-child(24), &:nth-child(26), &:nth-child(28), &:nth-child(30), &:nth-child(32), &:nth-child(34), &:nth-child(36), &:nth-child(38), &:nth-child(40), &:nth-child(42), &:nth-child(44), &:nth-child(46), &:nth-child(48), &:nth-child(50), &:nth-child(52), &:nth-child(54), &:nth-child(56), &:nth-child(58), &:nth-child(60), &:nth-child(62), &:nth-child(64), &:nth-child(66), &:nth-child(68), &:nth-child(70), &:nth-child(72), &:nth-child(74), &:nth-child(76), &:nth-child(78), &:nth-child(80), &:nth-child(82), &:nth-child(84), &:nth-child(86), &:nth-child(88), &:nth-child(90), &:nth-child(92), &:nth-child(94), &:nth-child(96), &:nth-child(98), &:nth-child(100) {
                        a {
                            background-color: $white-color;
                        }
                    }
                }
            }
        }
        .courses-instructor {
            .single-advisor-box {
                .advisor-content {
                    h3 {
                        font-size: 22px;
                        margin-bottom: 8px;
                    }
                    .sub-title {
                        display: block;
                        color: $main-color;
                        font-weight: 600;
                        margin-bottom: 15px;
                    }
                    .social-link {
                        padding-left: 0;
                        list-style-type: none;
                        margin-bottom: 0;

                        li {
                            display: inline-block;
                            margin-right: 3px;
                
                            &:last-child {
                                margin-right: 0;
                            }
                            a {
                                width: 30px;
                                height: 30px;
                                text-align: center;
                                background-color: #e1e1e1;
                                font-size: 18px;
                                color: $black-color;
                                position: relative;
                                border-radius: 2px;
                
                                i {
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    right: 0;
                                    transform: translateY(-48%);
                                }
                                &:hover {
                                    background-color: $main-color;
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }
        .courses-reviews {
            h3 {
                margin-bottom: 0;
                display: inline-block;
                margin-right: 15px;
                font: {
                    size: 21px;
                    weight: 800;
                };
            }
            .rating {
                display: inline-block;
                position: relative;
                top: 1px;
                
                span {
                    font-size: 19px;
                    color: #cecfd2;
                    margin-right: -2px;
    
                    &.checked {
                        color: orange;
                    }
                }
            }
            .rating-count {
                margin: {
                    top: 10px;
                    bottom: 20px;
                };
                span {
                    display: block;
                    font-size: $font-size;
                    color: $optional-color;
                }
            }
            .row {
                overflow: hidden;
                margin: {
                    left: 0;
                    right: 0;
                };
            }
            .side {
                float: left;
                width: 9%;
                margin-top: 10px;

                div {
                    font: {
                        size: $font-size;
                        weight: 700;
                    };
                }
            }
            .middle {
                margin-top: 14px;
                float: left;
                width: 82%;
            }
            .right {
                text-align: right;
            }
            .bar-container {
                width: 100%;
                background-color: #f1f1f1;
                text-align: center;
                color: $white-color;
                border-radius: 5px;
            }
            .bar-5 {
                width: 100%;
                height: 18px;
                background-color: #4CAF50;
                border-radius: 5px;
            }
            .bar-4 {
                width: 75%;
                height: 18px;
                background-color: #2196F3;
                border-radius: 5px;
                border-radius: 5px;
            }
            .bar-3 {
                width: 50%;
                height: 18px;
                background-color: #00bcd4;
                border-radius: 5px;
            }
            .bar-2 {
                width: 25%;
                height: 18px;
                background-color: #ff9800;
                border-radius: 5px;
            }
            .bar-1 {
                width: 0;
                height: 18px;
                background-color: #f44336;
                border-radius: 5px;
            }
        }
        .courses-review-comments {
            margin-top: 40px;
        
            h3 {
                border-bottom: 1px solid #f3f3f3;
                padding-bottom: 12px;
                margin: {
                    bottom: 8px;
                };
                font: {
                    size: 21px;
                    weight: 800;
                };
            }
            .user-review {
                border-bottom: 1px solid #f3f3f3;
                padding: 20px 0 20px 110px;
                position: relative;
        
                img {
                    position: absolute;
                    left: 0;
                    top: 20px;
                    width: 90px;
                    height: 90px;
                    border-radius: 5px;
                }
                .sub-comment {
                    margin-bottom: 8px;
                    font: {
                        weight: 700;
                    };
                }
                .review-rating {
                    display: block;
                    margin-bottom: 8px;
        
                    .review-stars {
                        display: inline-block;
        
                        i {
                            color: #cecfd2;
                            font-size: 18px;
                            display: inline-block;
                            margin-right: -3px;
            
                            &.checked {
                                color: orange;
                            }
                        }
                    }
                    span {
                        color: $black-color;
                        position: relative;
                        top: -2px;
                        font-weight: 800;
                        margin-left: 5px;
                    }
                }
            }
        }

        .tabs_item {
            display: none;
            
            &:first-child {
                display: block;
            }
        }
    }
}

.courses-details-info {
    background-color: #f8f9f8;
    border-radius: 5px;
    padding: 12px 12px 50px 12px;
    margin: {
        left: 15px;
        top: -240px;
    };
    .image {
        text-align: center;
        position: relative;

        .link-btn {
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
            z-index: 3;
            position: absolute;
            left: 0;
            top: 0;
        }
        .content {
            position: absolute;
            left: 0;
            z-index: 2;
            right: 0;
            top: 50%;
            transform: translateY(-50%);

            i {
                display: block;
                width: 60px;
                height: 60px;
                background-color: $main-color;
                color: $white-color;
                border-radius: 50%;
                line-height: 60px;
                font-size: 25px;
                margin: {
                    left: auto;
                    right: auto;
                    bottom: 15px;
                };
            }
            span {
                display: inline-block;
                color: $white-color;
                border-radius: 5px;
                padding: 4px 20px;
                background-color: $black-color;
                font: {
                    size: 17px;
                    weight: 700;
                };
            }
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            background-color: $black-color;
            opacity: 0.25;
        }
    }
    .info {
        margin-bottom: 0;
        list-style-type: none;
        padding: {
            left: 25px;
            right: 25px;
            top: 35px;
        };
        li {
            border-bottom: 1px solid #e5e5e7;
            color: $optional-color;
            font: {
                size: 17px;
                weight: 600;
            };
            padding: {
                top: 15px;
                bottom: 14px;
            };
            span {
                font-weight: 800;
                color: $black-color;
                position: relative;
                padding-left: 28px;

                i {
                    color: $main-color;
                    position: absolute;
                    left: 0;
                    font-weight: normal;
                    top: -2px;
                    font-size: 20px;
                }
            }
            &.price {
                padding-bottom: 10px;
                color: $main-color;
                font: {
                    size: 28px;
                    weight: 800;
                };
                span {
                    color: $black-color;
                    font: {
                        size: 17px;
                        weight: 800;
                    };
                }
            }
            &:first-child {
                padding-top: 0;
            }
        }
    }
    .btn-box {
        margin-top: 35px;
        padding: {
            left: 25px;
            right: 25px;
        };
        .default-btn {
            display: block;
            padding: {
                right: 30px;
                left: 30px;
                top: 12px;
                bottom: 12px;
            };
            i {
                position: relative;
                left: 0;
                top: 2px;
                margin-right: 8px;
                line-height: 1;
                font: {
                    weight: normal;
                    size: 20px;
                };
            }
            &:not(:first-child) {
                margin-top: 15px;
                background-color: transparent;
                color: $black-color;
                border: 1px solid $black-color;

                &:hover {
                    color: $white-color;
                    border-color: $black-color;
                }
            }
        }
    }
    .courses-share {
        text-align: center;
        margin-top: 20px;
        padding: {
            left: 25px;
            right: 25px;
        };
        .share-info {
            display: inline-block;
            position: relative;
            cursor: pointer;

            span {
                display: inline-block;
                color: $main-color;
                line-height: 1;
                font: {
                    size: $font-size;
                    weight: 700;
                };
                i {
                    color: $black-color;
                }
            }
            .social-link {
                padding-left: 0;
                list-style-type: none;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -35px;
                opacity: 0;
                visibility: hidden;
                transition: $transition;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;
                    margin-right: 3px;
        
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        background-color: #e1e1e1;
                        font-size: 18px;
                        color: $black-color;
                        position: relative;
                        border-radius: 2px;
        
                        i {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-48%);
                        }
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
            &:hover {
                .social-link {
                    opacity: 1;
                    visibility: visible;
                    bottom: -42px;
                }
            }
        }
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-us-area {
    position: relative;
    z-index: 1;

    &.bg-color {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: -1;
            background-color: #fafafb;
            height: 150px;
        }
    }
    .row {
        margin: {
            left: 0;
            right: 0;
        };
        .col-lg-6 {
            padding: {
                left: 0;
                right: 0;
            };
        }
    }
}
.why-choose-us-content {
    overflow: hidden;
    background-color: $black-color;
    padding: 130px 60px 100px;
    position: relative;
    z-index: 1;
    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);

    h2 {
        margin-bottom: 18px;
        color: $white-color;
        font: {
            size: 36px;
        };
    }
    p {
        color: #f0f0f0;
        margin-bottom: 18px;
    }
    .default-btn {
        margin-top: 5px;

        span {
            background-color: $white-color;
        }
        &:hover {
            color: $black-color;
        }
    }
}
.our-brand-partners-list {
    overflow: hidden;
    padding: 180px 60px 150px 90px;
    position: relative;
    background-color: #f4f4fb;
    -webkit-clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
    clip-path: polygon(0 10%, 100% 0, 100% 90%, 0% 100%);
    margin-left: -30px;

    h2 {
        margin-bottom: 30px;
        font: {
            size: 36px;
        };
    }
    .col-lg-6 {
        margin-bottom: 25px;
    }
    .view-all {
        display: inline-block;
        color: $main-color;
        position: relative;
        font-weight: 700;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background-color: $main-color;
            transition: $transition;
        }
        &:hover {
            &::before {
                width: 0;
            }
        }
    }
}
.map-shape2 {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 200s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}

/*================================================
Case Study Area CSS
=================================================*/
.case-study-area {
    position: relative;
    z-index: 1;

    &::before {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 34%;
        z-index: -1;
        background-color: #e1fbff;
    }
}
.single-case-study-item {
    position: relative;
    z-index: 1;

    .case-study-content {
        .sub-title {
            position: relative;
            display: block;
            text-transform: uppercase;
            margin-bottom: 15px;
            color: $main-color;
            font: {
                weight: 700;
                size: 15px;
            };
            img {
                margin-right: 5px;
                width: auto !important;
                display: inline-block !important;
                animation: {
                    name: rotateme;
                    duration: 40s;
                    iteration-count: infinite;
                    timing-function: linear;
                };
            }
        }
        h2 {
            margin-bottom: 12px;
            font: {
                size: 44px;
            };
        }
        .default-btn {
            margin-top: 10px;
        }
    }
    .case-study-image {
        text-align: center;

        a {
            border-radius: 50%;

            img {
                border-radius: 50%;
                width: auto !important;
                display: inline-block !important;
                border: 8px solid $white-color;
            }
        }
    }
}
.case-study-slides {
    .swiper-button-prev, .swiper-button-next {
        background-color: transparent;
        color: $optional-color;
        transition: $transition;
        font-size: 45px;

        &::after {
            font-size: 40px;
        }

        &:hover {
            background-color: transparent;
            color: $main-color;
        }
    }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
    position: relative;
    z-index: 1;
    
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
        max-width: 1920px;
    }
   
    &.bg-color {
        background: {
            image: url(./img/black-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        };
        .section-title {
            h2 {
                color: $white-color;

                .overlay {
                    background-color: $white-color;
                }
            }
            p {
                color: $white-color;
            }
        }
    }
}
.single-projects-box {
    margin-bottom: 30px;
    background-color: #fafafb;
    border-radius: 5px;
    text-align: center;

    .image {
        border-radius: 5px 5px 0 0;
        position: relative;
        overflow: hidden;

        img {
            border-radius: 5px 5px 0 0;
            transition: $transition;
        }
        &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: $main-color;
            border-radius: 5px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
        }
        .link-btn {
            display: inline-block;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 45px;
            height: 45px;
            line-height: 52.5px;
            text-align: center;
            z-index: 2;
            background-color: $white-color;
            border-radius: 50%;
            font-size: 30px;
            color: $main-color;
            margin-top: 20px;
            opacity: 0;
            visibility: hidden;

            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
    .content {
        border-radius: 0 0 5px 5px;
        padding: 25px;
        
        h3 {
            font-size: 24px;
            margin-bottom: 8px;
        }
        span {
            display: block;
            color: $main-color;
            font: {
                weight: 600;
                size: 16.5px;
            };
        }
    }
    &:hover {
        .image {
            img {
                transform: scale(1.3) rotate(5deg);
            }
            &::before {
                margin: 10px;
                opacity: 1;
                visibility: visible;
            }
            .link-btn {
                margin-top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }
    &.border {
        border-radius: 0;
        border: none !important;
        .image {
            border-radius: 0;
    
            img {
                border-radius: 0;
            }
            &::before {
                border-radius: 0;
            }
        }
        .content {
            border-radius: 0;
            border: 1px solid #ECF5FE;
            border-bottom: none;
            border-top: none;
        }
    }
}
.projects-items {
    margin: {
        left: -15px;
        right: -15px;
    };
}

/*================================================
CaseStudy/Projects Details Area CSS
=================================================*/
.case-studies-details-image {
    position: relative;
    margin-bottom: 30px;
    border-radius: 5px;

    img {
        border-radius: 5px;
        width: 100%;
    }
    .video-btn {
        cursor: pointer;
        position: absolute;
        left: 50%;
        top: 50%;
        display: inline-block;
        transform: translateY(-50%) translateX(-50%);
        width: 78px;
        line-height: 75px;
        height: 78px;
        border-radius: 50%;
        background-color: $white-color;
        text-align: center;
        color: $main-color;
        z-index: 1;
        font-size: 35px;

        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: $transition;
            border-radius: 50%;
            border: 1px solid $white-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover {
            background-color: $main-color;
            color: $white-color;

            &::after, &::before {
                border-color: $main-color;
            }
        }
    }
}
.case-studies-details-image-slides {
    &.owl-theme {
        margin-bottom: 30px;
        
        .owl-nav {
            margin-top: 0;
            opacity: 0;
            visibility: hidden;
            transition: $transition;

            [class*=owl-] {
                color: #87a1c1;
                font-size: 18px;
                margin: 0;
                padding: 0;
                background: $white-color;
                width: 45px;
                height: 45px;
                line-height: 40px;
                position: absolute;
                left: 20px;
                transition: $transition;
                top: 50%;
                transform: translateY(-50%);
                padding: {
                    left: 5px !important;
                };
                border: {
                    radius: 50%;
                    style: solid;
                    color: $white-color;
                    width: 1.5px;
                };
                &.owl-next {
                    left: auto;
                    right: 20px;
                    padding: {
                        left: 3px !important;
                    };
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                    border-color: $main-color;
                }
            }
        }
        &:hover {
            .owl-nav {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
.case-studies-details-desc {
    .sub-title {
        color: $main-color;
        font-weight: 700;
        display: block;
        margin-bottom: -20px;
    }
    h3 {
        line-height: 1.4;
        margin: {
            bottom: 15px;
            top: 30px;
        };
        font: {
            size: 28px;
        };
    }
    .image {
        padding-right: 15px;
        border-radius: 5px;
        margin: {
            top: 15px;
            bottom: 30px;
        };
        img {
            border-radius: 5px;
        }
    }
    .content {
        margin: {
            top: 15px;
            bottom: 30px;
        };
        h3 {
            font-size: 25px;
            margin: {
                top: 0;
            };
        }
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: 12px;
                font-weight: 600;
                color: $optional-color;
                position: relative;
                padding-left: 20px;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 8px;
                    height: 8px;
                    border-radius: 50%;
                    background-color: $main-color;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
.case-studies-details-info {
    background-color: $white-color;
    box-shadow: 0px -10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border-top: 3px solid $main-color;
    padding: 40px 30px;
    margin-left: 15px;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            position: relative;
            color: $optional-color;
            font: {
                weight: 600;
                size: 16.5px;
            };
            padding: {
                left: 35px;
                bottom: 20px;
            };
            span {
                color: $black-color;
                display: block;
                margin-bottom: 8px;
                font: {
                    size: 20px;
                    weight: 800;
                };
            }
            a {
                display: block;
                color: $optional-color;
                margin-bottom: 8px;
                
                &:last-child {
                    margin-bottom: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            .icon {
                position: absolute;
                left: 0;
                top: 3px;
                color: $main-color;
                font: {
                    weight: normal;
                    size: 25px;
                };
            }
            &:last-child {
                padding-bottom: 0;
                margin-bottom: 0;
                border-bottom: none;
            }
        }
    }
}

/*================================================
Scientist Area CSS
=================================================*/
.scientist-area {
    position: relative;
    z-index: 1;

    &.bg-color {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
            right: 0;
            background-color: #f1f8fb;
            height: 247px;
        }
    }
}
.single-scientist-box {
    text-align: center;
    margin-bottom: 30px;
    transition: $transition;

    .image {
        position: relative;
        z-index: 1;

        &::before {
            content: '';
            position: absolute;
            z-index: -1;
            left: 0;
            bottom: 0;
            right: 0;
            height: 83%;
            transition: $transition;
            border-radius: 5px 5px 0 0;
            background: {
                image: url(./img/scientist/shape1.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
    }
    .content {
        background-color: $white-color;
        padding: 20px;
        border-radius: 0 0 5px 5px;

        h3 {
            margin-bottom: 5px;
            font-size: 25px;
        }
        span {
            color: $optional-color;
            font: {
                size: 16px;
                weight: 600;
            };
        }
        .social {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 13px;
                bottom: 0;
            };
            li {
                display: inline-block;
                margin: {
                    left: 3px;
                    right: 3px;
                };
                a {
                    display: block;
                    background-color: $white-color;
                    border: 1.2px solid #ece4da;
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    line-height: 37px;
                    color: $optional-color;

                    &:hover {
                        background-color: $main-color;
                        border-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    &:hover {
        transform: translateY(-10px);

        .image {
            &::before {
                height: 80%;
            }
        }
    }
}
.col-lg-3 {
    &:nth-child(2), &:nth-child(4), &:nth-child(6), &:nth-child(8), &:nth-child(10) {
        .single-scientist-box {
            .image {
                &::before {
                    background: {
                        image: url(./img/scientist/shape2.png);
                    };
                }
            }
        }
    }
}
.scientist-section-title {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 12px;
        font: {
            size: 44px;
        };
    }
    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    .default-btn {
        margin-top: 5px;
    }
}
.single-scientist-item {
    margin-bottom: 30px;
    border-radius: 5px 5px 0 0;
    text-align: center;

    img {
        width: 100%;
        border-radius: 5px 5px 0 0;
    }
}
.scientist-box-list {
    padding-right: 15px;
    position: relative;
    z-index: 1;
    margin-bottom: 10px;

    .single-scientist-item {
        margin-bottom: 20px;
    }
    .col-lg-6 {
        &:nth-child(1) {
            .single-scientist-item {
                padding-right: 30px;
            }
        }
        &:nth-child(2) {
            .single-scientist-item {
                padding-right: 20px;
            }
        }
        &:nth-child(3) {
            .single-scientist-item {
                padding-left: 20px;
                margin-top: -100px;
            }
        }
    }
}
.single-scientist-item-box {
    text-align: center;
    margin-bottom: 30px;
    transition: $transition;

    .image {
        position: relative;

        .social {
            padding-left: 0;
            list-style-type: none;
            transition: $transition;
            opacity: 0;
            visibility: hidden;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            margin: {
                bottom: 0;
            };
            li {
                display: inline-block;
                margin: {
                    left: 3px;
                    right: 3px;
                };
                a {
                    display: block;
                    background-color: $white-color;
                    border: 1.2px solid #ece4da;
                    width: 34px;
                    height: 34px;
                    border-radius: 50%;
                    line-height: 37px;
                    color: $optional-color;

                    &:hover {
                        background-color: $main-color;
                        border-color: $main-color;
                        color: $white-color;
                    }
                }
            }
        }
    }
    .content {
        margin-top: 25px;
        border-radius: 0 0 5px 5px;

        h3 {
            margin-bottom: 5px;
            font-size: 23px;
        }
        span {
            color: $main-color;
            font: {
                size: 16px;
                weight: 600;
            };
        }
    }
    &:hover {
        .image {
            .social {
                opacity: 1;
                visibility: visible;
                bottom: 20px;
            }
        }
    }
}
.vector-shape4 {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    
    img {
        animation: movebounce 5s linear infinite;
    }
}
.vector-shape5 {
    position: absolute;
    left: 15%;
    bottom: -7%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 350s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.map-shape1 {
    position: absolute;
    right: 15%;
    top: 0;
    z-index: -1;
    opacity: 0.5;

    img {
        animation: {
            name: rotatemetwo;
            duration: 300s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@keyframes movebounce {
    0% {
        transform: translateY(0); 
    }
    50% {
        transform: translateY(-20px); 
    }
    100% {
        transform: translateY(0); 
    } 
}

/*================================================
Pricing Area CSS
=================================================*/
.single-pricing-box {
    margin-bottom: 30px;
    background-color: $white-color;
    padding-bottom: 30px;
    border-radius: 5px;
    transition: $transition;
    text-align: center;

    .pricing-header {
        padding: 20px 40px;

        h3 {
            margin-bottom: -5px;
            font-size: 24px;
        }
    }
    .price {
        color: $black-color;
        transition: $transition;
        padding: 10px 40px 5px;
        border: {
            top: 2px dashed #f4f5fe;
            bottom: 2px dashed #f4f5fe;
        };
        font: {
            size: 40px;
            weight: 900;
        };
        sup {
            top: -18px;
            margin-right: -6px;
            font: {
                size: 18px;
                weight: 500;
            };
        }
        sub {
            bottom: 2px;
            color: #4a6f8a;
            font: {
                size: 14px;
                weight: 500;
            };
        }
    }
    .pricing-features {
        margin: 0;
        text-align: left;
        padding: 30px;
        list-style-type: none;

        li {
            margin-bottom: 13px;
            position: relative;
            color: #444683;
            padding-right: 25px;

            i {
                color: green;
                position: absolute;
                top: 2px;
                right: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .red {
                color: red;
            }
        }
    }
    .btn-box {
        text-align: left;
        padding: 0 30px;
    }
    &:hover {
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.07);
        transform: translateY(-10px);
    }
}

/*================================================
SEO Analysis Area CSS
=================================================*/
.analysis-form {
    padding-left: 15px;
    
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 12px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 13px;
        font: {
            size: 44px;
        };
    }
    form {
        margin-top: 30px;

        .default-btn {
            margin-top: 10px;
        }
    }
}

/*================================================
Overview Area CSS
=================================================*/
.overview-box {
    align-items: center !important;
    display: flex;
    flex-wrap: wrap;
    margin: {
        right: -15px;
        left: -15px;
        bottom: 100px;
    };
    &:last-child {
        margin-bottom: 0;
    }
    .overview-content {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        };
        .content {
            padding-right: 30px;

            .sub-title {
                color: $main-color;
                display: block;
                text-transform: uppercase;
                margin-bottom: 8px;
                font: {
                    size: 14px;
                    weight: 700;
                };
            }
            h2 {
                margin-bottom: 12px;
                font: {
                    size: 35px;
                };
            }
            .features-text {
                margin-top: 30px;
        
                h4 {
                    margin-bottom: 15px;
                    font: {
                        size: 18px;
                    };
                    i {
                        font-size: 16px;
                        margin-right: 4px;
                        color: $main-color;
                    }
                }
            }
            &.right-content {
                padding: {
                    left: 30px;
                    right: 0;
                };
            }
            .features-list {
                display: flex;
                flex-wrap: wrap;
                padding-left: 0;
                list-style-type: none;
                margin: {
                    bottom: 0;
                    top: 10px;
                    left: -10px;
                    right: -10px;
                };
                li {
                    flex: 0 0 50%;
                    max-width: 50%;
                    margin-top: 10px;
                    padding: {
                        left: 10px;
                        right: 10px;
                    };
                    span {
                        display: block;
                        background-color: #F8FAFF;
                        border-radius: 5px;
                        padding: 13px 12px 10px 40px;
                        z-index: 1;
                        position: relative;
                        transition: $transition;
                        font: {
                            weight: 800;
                            size: 15.5px;
                        };
                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 0;
                            height: 100%;
                            border-radius: 5px;
                            background-color: $main-color;
                            z-index: -1;
                            transition: $transition;
                        }
                        i {
                            font-size: 18px;
                            color: $white-color;
                            background-color: $main-color;
                            width: 23px;
                            height: 23px;
                            line-height: 23px;
                            transition: $transition;
                            text-align: center;
                            border-radius: 3px;
                            position: absolute;
                            left: 8px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                        &:hover {
                            color: $white-color;
        
                            &::before {
                                width: 100%;
                            }
                            i {
                                background-color: $white-color;
                                color: $black-color;
                            }
                        }
                    }
                }
            }
        }
    }
    .overview-image {
        flex: 0 0 50%;
        max-width: 50%;
        padding: {
            left: 15px;
            right: 15px;
        };
        .image {
            text-align: center;
        }
    }
}

/*================================================
Explore Learning Area CSS
=================================================*/
.explore-learning-area {
    position: relative;
    z-index: 1;
}
.explore-learning-content {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 16px;
        font: {
            size: 44px;
        };
    }
    .explore-learing-btn {
        display: inline-block;
        margin-top: 5px;
        color: $main-color;
        position: relative;
        font: {
            size: 16.5px;
            weight: 700;
        };
        &::before {
            width: 0;
            height: 1px;
            background-color: $black-color;
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            transition: $transition;
        }
        &:hover {
            color: $black-color;

            &::before {
                width: 100%;
            }
        }
    }
}
.explore-learning-video {
    text-align: center;
    border-radius: 5px;
    position: relative;

    img {
        border-radius: 5px;
    }
    .video-btn {
        cursor: pointer;
        display: inline-block;
        width: 85px;
        height: 85px;
        font-size: 35px;
        text-align: center;
        line-height: 85px;
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        transform: translateY(-50%) translateX(-50%);
        background-color: $main-color;
        border-radius: 50%;
        color: $white-color;
        padding-left: 5px;
    
        &::after, &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            transition: $transition;
            border-radius: 50%;
            border: 1px solid $main-color;
        }
        &::before {
            animation: ripple 2s linear infinite;
        }
        &::after {
            animation: ripple 2s linear 1s infinite;
        }
        &:hover {
            color: $white-color;
            background-color: $black-color;
    
            &::before, &::after {
                border-color: $black-color;
            }
        }
    }
}
@keyframes ripple {
    0% {
        transform: scale(1);
    }
    75% {
        transform: scale(1.75);
        opacity: 1;
    }
    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;
        border: none;
        margin: {
            bottom: 0;
        };
        .accordion__item {
            border-radius: 5px;
            display: block;
            box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
            background: $white-color;
            margin-bottom: 20px;
            padding: 0;
            border: none;

            &:last-child {
                margin-bottom: 0;
            }
        }
        .accordion__button {
            background-color: #fff;
            color: $black-color;
            position: relative;
            display: block;
            padding: 20px 30px;
            border-radius: 5px;
            font: {
                size: 18px;
                weight: 800;
            };
            i {
                position: absolute;
                left: 25px;
                top: 18px;
                font-size: 25px;
                transition: $transition;
            }
            &.active {
                i {
                    transform: rotate(180deg);
                }
            }
        }
        .accordion__panel {
            position: relative;
            margin-top: -5px;
            padding: {
                bottom: 20px;
                right: 25px;
                left: 50px;
                top: 0;
            };
            p {
                line-height: 1.8;
                font-size: 15px;
            }
            &.show {
                display: block;
            }
        }
    }
}
.tab {
    .tabs-item {
        display: none;
        
        &:first-child {
            display: block;
        }
    }
}

/*================================================
Industries Serve Area CSS
=================================================*/
.industries-serve-area {
    position: relative;
    z-index: 1;
}
.single-industries-serve-box {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    color: $black-color;
    border-radius: 5px;
    background-color: #f4f4fb;
    padding: 20px 15px 20px 60px;
    transition: $transition;
    z-index: 1;
    font: {
        size: 24px;
        weight: 800;
    };
    .icon {
        position: absolute;
        left: -10px;
        top: 50%;
        line-height: 1;
        transform: translateY(-50%);
        color: #c3c3c9;
        transition: $transition;
        font: {
            size: 55px;
            weight: normal;
        };
    }
    &::before {
        border-radius: 5px;
        position: absolute;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        content: '';
        z-index: -1;
        transition: $transition;
        background-color: $main-color;
    }
    .link-btn {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 5px;
        z-index: 2;
    }
    &:hover {
        color: $white-color;

        .icon {
            color: $white-color;
        }
        &::before {
            width: 100%;
        }
    }
}
.vector-shape11 {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    
    img {
        animation: movebounce 5s linear infinite;
    }
}

/*================================================
Gallery Area CSS
=================================================*/
.single-gallery-item {
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
    border-radius: 5px;
    overflow: hidden;
    cursor: zoom-in;

    a {
        display: block;
        border-radius: 5px;
        
        img {
            transition: $transition;
        }
    }
    &:hover {
        a {
            img {
                transform: scale(1.1);
                filter: blur(2px);
            }
        }
    }
}

/*================================================
Products Area CSS
=================================================*/
.single-products-box {
    position: relative;
    margin-bottom: 30px;

    .products-image {
        text-align: center;
        position: relative;
        overflow: hidden;

        a {
            position: relative;
            display: block;

            img {
                transition: all 1.5s cubic-bezier(0,0,.2,1);
            }
        }
        .products-button {
            position: absolute;
            top: 20px;
            right: 20px;
            z-index: 1;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    margin-bottom: 10px;
                    opacity: 0;
                    visibility: hidden;
                    transform: translateX(30px);
                    transition: all 0.3s ease-in-out 0s;

                    a {
                        display: inline-block;
                        font-size: 20px;
                        line-height: 1;
                        color: $black-color;
                        position: relative;
                        box-shadow: 0 0 4px rgba(0,0,0,.07);
                        background-color: $white-color;
                        width: 40px;
                        height: 40px;
                        text-align: center;
                        line-height: 45px;
                        border-radius: 50%;

                        .tooltip-label {
                            right: 110%;
                            top: 5px;
                            transform: translateX(0);
                            position: absolute;
                            padding: 0 10px;
                            visibility: hidden;
                            white-space: nowrap;
                            opacity: 0;
                            color: transparent;
                            background-color: transparent;
                            transition: all .2s ease-in-out;
                            background-color: $main-color;
                            color: $white-color;
                            line-height: 28px;
                            font: {
                                size: 12px;
                                weight: 800;
                            };
                            &::before {
                                content: '';
                                background-color: $main-color;
                                top: 50%;
                                position: absolute;
                                transition: all .2s ease-in-out;
                                right: -1px;
                                width: 10px;
                                height: 10px;
                                transform: rotate(45deg) translateY(-50%);
                            }
                        }
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;

                            .tooltip-label {
                                visibility: visible;
                                opacity: 1;
                                right: 135%;
                            }
                        }
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    &:nth-child(1) {
                        transition-delay: 0.1s;
                    }
                    &:nth-child(2) {
                        transition-delay: 0.2s;
                    }
                    &:nth-child(3) {
                        transition-delay: 0.3s;
                    }
                }
            }
        }
        .sale-tag {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: red;
            color: $white-color;
            width: 55px;
            height: 55px;
            line-height: 55px;
            text-align: center;
            border-radius: 50%;
            z-index: 2;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            };
        }
        .new-tag {
            position: absolute;
            left: 20px;
            top: 20px;
            background-color: green;
            color: $white-color;
            text-align: center;
            z-index: 2;
            padding: 1px 7px;
            text-transform: uppercase;
            font: {
                weight: 700;
                size: 14px;
            };
        }
    }
    .products-content {
        margin-top: 25px;
        position: relative;

        h3 {
            margin-bottom: 0;
            font: {
                size: 22px;
            };
            a {
                display: inline-block;
            }
        }
        .price {
            margin-top: 13px;
            transition: $transition;
            font: {
                size: 16px;
                weight: 800;
            };
            .old-price {
                text-decoration: line-through;
                color: #999999;
                font-weight: 700;
            }
        }
        .star-rating {
            position: absolute;
            right: 0;
            bottom: 0;
            color: #f49f0b;

            i {
                display: inline-block;
                margin-left: -2px;
            }
        }
        .add-to-cart {
            position: absolute;
            left: 0;
            bottom: 0;
            text-transform: uppercase;
            transition: $transition;
            display: inline-block;
            color: $optional-color;
            opacity: 0;
            visibility: hidden;
            transform: translateX(20px);
            font: {
                size: 14px;
                weight: 800;
            };
            &:hover {
                color: $main-color;
            }
        }
    }
    &:hover {
        .products-content {
            .price {
                opacity: 0;
                visibility: hidden;
            }
            .add-to-cart {
                transform: translateX(0);
                opacity: 1;
                visibility: visible;
            }
        }
        .products-image {
            a {
                img {
                    transform: scale3d(1.1,1.1,1.1) translateZ(0);
                }
            }
            .products-button {
                ul {
                    li {
                        transform: translateX(0);
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }
}
.tracer-grid-sorting {
    margin-bottom: 40px;

    .result-count {
        p {
            font: {
                weight: 500;
                size: 16.5px;
            };
            .count {
                font-weight: 700;
                color: $black-color;
            }
        }
    }
    .ordering {
        text-align: right;

        label {
            display: inline-block;
            margin-bottom: 0;
            color: $optional-color;
            margin-right: 5px;
            font: {
                size: 16.5px;
                weight: 700;
            };
        }
    }
}

/*================================================
Products Details Area CSS
=================================================*/
.products-details-image {
    border-radius: 5px;

    a {
        display: block;
        border-radius: 5px;

        img {
            border-radius: 5px;
        }
    }
}
.products-details-desc {
    padding-left: 30px;

    h3 {
        margin-bottom: 15px;
        font: {
            size: 25px;
        }
    }
    .price {
        margin-bottom: 15px;
        color: $main-color;
        font: {
            size: 15.5px;
            weight: 700;
        }
        .old-price {
            text-decoration: line-through;
            color: #828893;
            font-weight: normal;
        }
    }
    .products-review {
        margin-bottom: 18px;

        .rating {
            display: inline-block;
            padding-right: 5px;
            font-size: 18px;

            i {
                color: #ffba0a;
                display: inline-block;
                margin-right: -2px;
            }
        }
        .rating-count {
            display: inline-block;
            color: $black-color;
            border-bottom: 1px solid $black-color;
            line-height: initial;
            position: relative;
            top: -3px;
            font-weight: 600;

            &:hover {
                color: $main-color;
                border-color: $main-color;
            }
        }
    }
    .products-meta {
        margin-top: 20px;

        span {
            display: block;
            color: $black-color;
            margin-bottom: 10px;
            font: {
                size: $font-size;
                weight: 700;
            };
            span {
                display: inline-block;
                margin-bottom: 0;
                font-weight: 600;

                &.sku {
                    color: $main-color;
                }
                &.in-stock {
                    color: $optional-color;
                }
            }
            a {
                display: inline-block;
                color: $optional-color;
                font-weight: 600;

                &:hover, &:focus {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .products-add-to-cart {
        margin-top: 25px;

        .input-counter {
            max-width: 130px;
            min-width: 130px;
            margin-right: 10px;
            text-align: center;
            display: inline-block;
            position: relative;    

            span {
                position: absolute;
                top: 0;
                background-color: transparent;
                cursor: pointer;
                color: #d0d0d0;
                width: 50px;
                height: 100%;
                line-height: 55px;
                transition: $transition;
                font-size: 25px;

                &.minus-btn {
                    left: 0;
                }
                &.plus-btn {
                    right: 0;
                }
                &:hover {
                    color: $main-color;
                }
            }
            input {
                height: 50px;
                color: $black-color;
                outline: 0;
                display: block;
                border: none;
                background-color: #f8f8f8;
                text-align: center;
                width: 100%;
                font: {
                    size: 17px;
                    weight: 600;
                }
                &::placeholder {
                    color: $black-color;
                }
            }
        }
        .default-btn {
            top: -2px;
            padding: {
                top: 12.5px;
                bottom: 12.5px;
            };
        }
    }
    .products-share {
        margin-top: 30px;

        .social {
            padding-left: 0;
            list-style-type: none;
            margin-bottom: 0;

            li {
                display: inline-block;

                span {
                    display: inline-block;
                    margin-right: 3px;
                    font-weight: 700;
                    position: relative;
                    top: -2px;
                }
                a {
                    display: block;
                    width: 32px;
                    height: 32px;
                    line-height: 34px;
                    border-radius: 50%;
                    background-color: $main-color;
                    color: $white-color;
                    border: 1px solid $main-color;
                    text-align: center;
                    font-size: 17px;
                    margin-left: 2px;
    
                    &:hover, &:focus {
                        color: $main-color;
                        background-color: transparent;
                    }
                    &.facebook {
                        background-color: #3b5998;
                        border-color: #3b5998;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #3b5998;
                            background-color: transparent;
                        }
                    }
                    &.twitter {
                        background-color: #1da1f2;
                        border-color: #1da1f2;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #1da1f2;
                            background-color: transparent;
                        }
                    }
                    &.linkedin {
                        background-color: #007bb5;
                        border-color: #007bb5;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #007bb5;
                            background-color: transparent;
                        }
                    }
                    &.instagram {
                        background-color: #c13584;
                        border-color: #c13584;
                        color: $white-color;

                        &:hover, &:focus {
                            color: #c13584;
                            background-color: transparent;
                        }
                    }
                }
            }
        }
    }
}
.products-details-tabs {
    margin-top: 50px;

    .nav {
        text-align: center;
        padding-left: 0;
        margin-bottom: 40px;
        list-style-type: none;
        display: block;

        .nav-item {
            display: inline-block;
            margin: {
                left: 15px;
                right: 15px;
            };
            .nav-link {
                color: #cccccc;
                border: none;
                border-bottom: 1px solid #eeeeee;
                padding: 0;
                background-color: transparent;
                position: relative;
                padding-bottom: 8px;
                font: {
                    size: 20px;
                    weight: 800;
                };
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 0;
                    height: 3px;
                    background-color: $main-color;
                    transition: $transition;
                    bottom: -2px;
                }
                &:hover, &.active {
                    color: $black-color;

                    &::before {
                        width: 100%;
                    }
                }
            }
        }
    }
    .tab-content {
        .tab-pane {
            max-width: 720px;
            margin: {
                left: auto;
                right: auto;
            };
            p {
                line-height: 1.8;
            }
            ul {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    top: 20px;
                    bottom: 0;
                };
                li {
                    margin-bottom: 12px;
                    position: relative;
                    color: $optional-color;
                    padding-left: 15px;
                    font-weight: 600;

                    &::before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 8px;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        background-color: $black-color;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .products-reviews {
                h3 {
                    margin-bottom: 0;
                    display: inline-block;
                    margin-right: 15px;
                    font: {
                        size: 25px;
                    };
                }
                .rating {
                    display: inline-block;
                    
                    span {
                        font-size: 17px;
                        color: #cecfd2;
                        margin-right: -2px;
        
                        &.checked {
                            color: orange;
                        }
                    }
                }
                .rating-count {
                    margin: {
                        top: 10px;
                        bottom: 20px;
                    };
                    span {
                        display: block;
                        font-size: $font-size;
                        color: $optional-color;
                    }
                }
                .row {
                    overflow: hidden;
                    margin: {
                        left: 0;
                        right: 0;
                    };
                }
                .side {
                    float: left;
                    width: 9%;
                    margin-top: 10px;
        
                    div {
                        font: {
                            size: $font-size;
                            weight: 700;
                        };
                    }
                }
                .middle {
                    margin-top: 14px;
                    float: left;
                    width: 82%;
                }
                .right {
                    text-align: right;
                }
                .bar-container {
                    width: 100%;
                    background-color: #f1f1f1;
                    text-align: center;
                    color: $white-color;
                    border-radius: 5px;
                }
                .bar-5 {
                    width: 100%;
                    height: 18px;
                    background-color: #4CAF50;
                    border-radius: 5px;
                }
                .bar-4 {
                    width: 75%;
                    height: 18px;
                    background-color: #2196F3;
                    border-radius: 5px;
                    border-radius: 5px;
                }
                .bar-3 {
                    width: 50%;
                    height: 18px;
                    background-color: #00bcd4;
                    border-radius: 5px;
                }
                .bar-2 {
                    width: 25%;
                    height: 18px;
                    background-color: #ff9800;
                    border-radius: 5px;
                }
                .bar-1 {
                    width: 0;
                    height: 18px;
                    background-color: #f44336;
                    border-radius: 5px;
                }
            }
            .products-review-comments {
                margin-top: 40px;
            
                h3 {
                    border-bottom: 1px solid #f3f3f3;
                    padding-bottom: 10px;
                    margin: {
                        top: 0;
                        bottom: 10px;
                    };
                    font: {
                        size: 24px;
                        weight: 800;
                    };
                }
                .user-review {
                    border-bottom: 1px solid #f3f3f3;
                    padding: 20px 0 20px 110px;
                    position: relative;
            
                    img {
                        position: absolute;
                        left: 0;
                        top: 20px;
                        width: 90px;
                        height: 90px;
                        border-radius: 5px;
                    }
                    .sub-comment {
                        margin-bottom: 8px;
                        font: {
                            weight: 700;
                        };
                    }
                    .review-rating {
                        display: block;
                        margin-bottom: 8px;
            
                        .review-stars {
                            display: inline-block;
            
                            i {
                                color: #cecfd2;
                                font-size: 18px;
                                display: inline-block;
                                margin-right: -3px;
                
                                &.checked {
                                    color: orange;
                                }
                            }
                        }
                        span {
                            color: $black-color;
                            position: relative;
                            top: -2px;
                            font-weight: 800;
                            margin-left: 5px;
                        }
                    }
                }
            }
            .review-form-wrapper {
                margin-top: 30px;

                h3 {
                    margin-bottom: 10px;
                    font: {
                        size: 24px;
                        weight: 800;
                    };
                }
                .comment-notes {
                    span {
                        color: red;
                    }
                }
                form {
                    margin-top: 20px;
                    text-align: center;

                    .form-group {
                        margin-bottom: 25px;
                        text-align: left;
                    }
                    .rating {
                        text-align: left;
                        overflow: hidden;
                        max-width: 115px;
                        margin: {
                            top: -5px;
                            bottom: 20px;
                        };
                        label {
                            float: right;
                            position: relative;
                            width: 23px;
                            height: 23px;
                            cursor: pointer;

                            &:not(:first-of-type) {
                                padding-right: 5px;
                            }
                            &:before {
                                content: "\2605";
                                transition: $transition;
                                font-size: 27px;
                                color: #CCCCCC;
                                line-height: 1;
                            }
                        }
                        input {
                            display: none;
                        }
                        input:checked ~ label:before, &:not(:checked) > label:hover:before, &:not(:checked) > label:hover ~ label:before {
                            color: #f6b500;
                        }
                    }
                    .comment-form-cookies-consent {
                        text-align: left;
                        margin-bottom: 0;
            
                        [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
                            display: none;
                        }
                        [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                            position: relative;
                            padding-left: 28px;
                            cursor: pointer;
                            line-height: 20px;
                            display: inline-block;
                            margin-bottom: 0;
                            color: $optional-color;
                            font: {
                                weight: 600;
                            };
                        }
                        [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 19px;
                            height: 19px;
                            transition: all 0.2s ease;
                            border: 1px solid #f5f5f5;
                            border-radius: 3px;
                            background: #f5f5f5;
                        }
                        [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                            content: '';
                            width: 8px;
                            height: 8px;
                            background: $main-color;
                            position: absolute;
                            top: 5.5px;
                            left: 6px;
                            -webkit-transition: all 0.2s ease;
                            transition: all 0.2s ease;
                        }
                        [type="checkbox"]:not(:checked) + label:after {
                            opacity: 0;
                            -webkit-transform: scale(0);
                            transform: scale(0);
                        }
                        [type="checkbox"]:checked + label:after {
                            opacity: 1;
                            -webkit-transform: scale(1);
                            transform: scale(1);
                        }
                        [type="checkbox"]:hover + label:before {
                            border-color: $main-color;
                        }
                        [type="checkbox"]:checked + label:before {
                            border-color: $main-color;
                        }
                    }
                    button {
                        margin-top: 22px;
                        border: none;
                        display: inline-block;
                        text-align: center;
                        overflow: hidden;
                        color: $white-color;
                        background-color: $main-color;
                        transition: $transition;
                        border-radius: 5px;
                        padding: 13px 60px;
                        font: {
                            weight: 700;
                            size: $font-size;
                        };
                        &:hover {
                            background-color: $black-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Cart Area CSS
=================================================*/
.cart-table {
    table {
        margin-bottom: 0;

        thead {
            tr {
                th {
                    border-bottom-width: 0px;
                    vertical-align: middle;
                    padding: 0 0 15px;
                    text-transform: uppercase;
                    border: none;
                    white-space: nowrap;
                    font: {
                        size: 16px;
                        weight: 800;
                    };
                }
            }
        }
        tbody {
            tr {
                td {
                    vertical-align: middle;
                    color: $optional-color;
                    white-space: nowrap;
                    font: {
                        weight: 700;
                        size: 16px;
                    };
                    padding: {
                        left: 0;
                        right: 0;
                    };
                    border: {
                        color: #eaedff;
                        left: none;
                        right: none;
                    };
                    &.product-thumbnail {
                        a {
                            display: block;

                            img {
                                width: 80px;
                            }
                        }
                    }
                    &.product-name {
                        a {
                            display: inline-block;
                        }
                    }
                    &.product-subtotal {
                        .remove {
                            color: red;
                            float: right;
                            position: relative;
                            top: -1px;
                            font-size: 18px;
                        }
                    }
                    &.product-quantity {
                        .input-counter {
                            max-width: 130px;
                            min-width: 130px;
                            text-align: center;
                            display: inline-block;
                            position: relative;    
                
                            span {
                                position: absolute;
                                top: 0;
                                background-color: transparent;
                                cursor: pointer;
                                color: #d0d0d0;
                                width: 40px;
                                height: 100%;
                                line-height: 50px;
                                transition: $transition;
                                font-size: 22px;
                
                                &.minus-btn {
                                    left: 0;
                                }
                                &.plus-btn {
                                    right: 0;
                                }
                                &:hover {
                                    color: $main-color;
                                }
                            }
                            input {
                                height: 45px;
                                color: $black-color;
                                outline: 0;
                                display: block;
                                border: none;
                                background-color: #f8f8f8;
                                text-align: center;
                                width: 100%;
                                font: {
                                    size: 17px;
                                    weight: 600;
                                }
                                &::placeholder {
                                    color: $black-color;
                                }
                            }
                        }
                    }
                    &.product-subtotal {
                        overflow: hidden;
                    }
                }
            }
        }
    }
}
.cart-buttons {
    margin-top: 30px;

    .shopping-coupon-code {
        position: relative;
        max-width: 530px;

        button {
            position: absolute;
            right: 0;
            top: 0;
            height: 50px;
            background: $black-color;
            color: $white-color;
            border: none;
            padding: 0 25px;
            line-height: 48px;
            outline: 0;
            transition: $transition;
            font: {
                size: 16px;
                weight: 700;
            };
            &:hover {
                background-color: $main-color;
            }
        }
    }
}
.cart-totals {
    background: $white-color;
    padding: 40px;
    max-width: 500px;
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: {
        top: 60px;
        left: auto;
        right: auto;
    };
    h3 {
        margin-bottom: 25px;
        font: {
            size: 25px;
        };
    }
    ul {
        padding-left: 0;
        margin: 0 0 25px;
        list-style-type: none;
        
        li {
            border: 1px solid #eaedff;
            padding: 10px 15px;
            color: $black-color;
            overflow: hidden;
            font: {
                weight: 700;
                size: 16px;
            };
            &:first-child {
                border: {
                    bottom: none;
                }
            }
            &:last-child {
                font-size: 19px;
                border: {
                    top: none;
                };
                span {
                    color: $black-color;
                    font-weight: 600;
                }
            }
            span {
                float: right;
                color: $optional-color;
                font: {
                    weight: normal;
                };
            }
        }
    }
}

/*================================================
Checkout Area CSS
=================================================*/
.user-actions {
    box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
    background: $white-color;
    padding: 15px 20px;
    border-top: 3px solid $main-color;
    position: relative;
    margin-bottom: 40px;

    i {
        color: $main-color;
        margin-right: 2px;
        font-size: 20px;
        position: relative;
        top: 3px;
    }
    span {
        display: inline-block;
        color: $main-color;
        font: {
            weight: 700;
            size: 16px;
        };
        a {
            display: inline-block;
        }
    }
}
.billing-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
        };
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .form-group {
        margin-bottom: 25px;

        label {
            display: block;
            margin-bottom: 8px;
            font: {
                weight: 700;
                size: 15px;
            };
            .required {
                color: red;
            }
        }
        .nice-select {
            display: block;
            width: 215px;
            float: unset;
            width: 100%;
            background: #f8f8f8;
            border-color: #eeeeee;
            color: $black-color;
            transition: $transition;
            padding: 0 0 0 12px;
            height: 45px;
            line-height: 45px;
            font: {
                size: 16px;
                weight: 700;
            };
            .list {
                background-color: $white-color;
                border-radius: 5px;
                box-shadow: 0 10px 30px rgba(0,0,0,.2);
                list-style-type: none;
                border: none;
                width: 100%;
                margin: {
                    bottom: 0;
                    top: 0;
                };
                padding: {
                    left: 0;
                    top: 10px;
                    bottom: 10px;
                };
                .option {
                    line-height: 38px;
                    min-height: 38px;
                    color: $black-color;
                    position: relative;
                    transition: $transition;
                    padding: {
                        left: 32px;
                        right: 25px;
                    };
                    font: {
                        size: 16px;
                        weight: 700;
                    };
                    &:hover {
                        background-color: $main-color !important;
                        color: $white-color;

                        &::before {
                            color: $white-color;
                        }
                    }
                    &.focus, &.selected.focus {
                        background-color: transparent !important;
                        color: $black-color;
                    }
                    &::before {
                        content: "\f10f";
                        position: absolute;
                        left: 10px;
                        top: 0;
                        opacity: 0;
                        visibility: hidden;
                        transition: $transition;
                        color: $main-color;
                        font: {
                            family: Flaticon;
                            size: 12px;
                        };
                    }
                    &.selected {
                        &::before {
                            opacity: 1;
                            visibility: visible;
                        }
                        &:hover {
                            background-color: $main-color !important;
                            color: $white-color;

                            &::before {
                                color: $white-color;
                            }
                        }
                    }
                }
            }
            &:after {
                border-color: $black-color;
                height: 8px;
                width: 8px;
                margin-top: -5px;
            }
            &:hover {
                border-color: $main-color;
                background-color: transparent;
            }
        }
    }
    .form-check {
        margin-bottom: 20px;

        .form-check-label {
            color: $black-color;
            font-weight: 600;
        }
        label {
            position: relative;
            left: -3px;
            top: 0;
            font: {
                weight: 500;
            }
        }
    }
    .col-lg-12 {
        &:last-child {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
}
.order-details {
    .title {
        margin-bottom: 30px;
        position: relative;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;
        font: {
            size: 24px;
        };
        &::before {
            content: '';
            position: absolute;
            background: $main-color;
            bottom: -1px;
            left: 0;
            width: 50px;
            height: 1px;
        }
    }
    .order-table {
        table {
            margin-bottom: 0;
    
            thead {
                tr {
                    th {
                        border-bottom-width: 0;
                        vertical-align: middle;
                        border-color: #eaedff;
                        padding: 12px 20px 10px;
                        white-space: nowrap;
                        text-transform: uppercase;
                        font: {
                            weight: 800;
                            size: 16px;
                        };
                    }
                }
            }
            tbody {
                tr {
                    td {
                        vertical-align: middle;
                        color: $optional-color;
                        white-space: nowrap;
                        border-color: #eaedff;
                        font: {
                            size: 15px;
                            weight: 700;
                        };
                        padding: {
                            left: 20px;
                            right: 20px;
                            top: 15px;
                            bottom: 13px;
                        };
                        &.product-name {
                            a {
                                display: inline-block;
                            }
                        }
                        &.order-subtotal, &.order-shipping , &.total-price {
                            span {
                                color: $black-color;
                                font: {
                                    weight: 800;
                                    size: $font-size;
                                };
                            }
                        }
                        &.shipping-price, &.order-subtotal-price, &.product-subtotal {
                            color: $black-color;
                            font: {
                                weight: 800;
                                size: $font-size;
                            };
                        }
                    }
                }
            }
        }
    }
    .payment-box {
        background-color: $white-color;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        margin-top: 30px;
        padding: 30px;
        
        .payment-method {
            p {
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        display: none;
                    }
                }
                [type="radio"] {
                    &:checked, &:not(:checked) {
                        + label {
                            padding-left: 27px;
                            cursor: pointer;
                            display: block;
                            color: $black-color;
                            position: relative;
                            margin-bottom: 8px;
                            font: {
                                weight: 800;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 4px;
                                width: 18px;
                                height: 18px;
                                border: 1px solid #dddddd;
                                border-radius: 50%;
                                background: $white-color;
                            }
                            &::after {
                                content: '';
                                width: 12px;
                                height: 12px;
                                background: $main-color;
                                position: absolute;
                                top: 7px;
                                left: 3px;
                                border-radius: 50%;
                                transition: $transition;
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:not(:checked) {
                        + label {
                            &::after {
                                opacity: 0;
                                visibility: hidden;
                                transform: scale(0);
                            }
                        }
                    }
                }
                [type="radio"] {
                    &:checked {
                        + label {
                            &::after {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
        .default-btn {
            margin-top: 15px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            border-radius: 3px;
        }
    }
}

/*================================================
Upcoming Bootcamps Area CSS
=================================================*/
.upcoming-bootcamps-area {
    position: relative;
    z-index: 1;
}
.upcoming-bootcamps-content {
    text-align: center;

    h2 {
        max-width: 720px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
        font: {
            size: 44px;
        };
    }
    .default-btn {
        margin: {
            bottom: -5px;
            top: 30px;
        };
    }
}

/*================================================
Events Area CSS
=================================================*/
.single-events-box {
    transition: $transition;
    background-color: $white-color;
    margin-bottom: 30px;

    .image {
        position: relative;

        .date {
            position: absolute;
            right: 0;
            bottom: 0;
            display: inline-block;
            background-color: $white-color;
            color: $main-color;
            padding: 8px 20px;
            border-radius: 5px 0 0 0;
            font: {
                size: 15px;
                weight: 700;
            };
            &::before {
                content: '';
                position: absolute;
                right: 20px;
                bottom: 0;
                width: 60px;
                height: 1px;
                background-color: #eeeeee;
            }
        }
    }
    .content {
        padding: 20px;
        position: relative;

        h3 {
            margin-bottom: 0;
            line-height: 1.3;
            font: {
                size: 24px;
            };
            a {
                display: inline-block;
            }
        }
        .location {
            display: block;
            color: $optional-color;
            margin-top: 13px;
            position: relative;
            padding-left: 22px;
            font: {
                size: $font-size;
                weight: 600;
            };
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 2px;
                font-size: 18px;
            }
        }
        &::before {
            left: 20px;
            position: absolute;
            bottom: 0;
            content: '';
            height: 2px;
            width: 45px;
            background-color: #d6d6d6;
            transition: $transition;
        }
    }
    &:hover {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
        transform: translateY(-10px);

        .content {
            &::before {
                width: 65px;
                background-color: $main-color;
            }
        }
    }
}

/*================================================
Events Details Area CSS
=================================================*/
.events-details-header {
    margin-bottom: 40px;

    ul {
        margin-bottom: 0;
        list-style-type: none;
        background-color: #f8f9f8;
        padding: {
            left: 30px;
            right: 30px;
            top: 14px;
            bottom: 14px;
        };
        li {
            display: inline-block;
            margin-right: 18px;
            color: $optional-color;
            position: relative;
            padding-left: 22px;
            font: {
                size: 16px;
                weight: 600;
            };
            i {
                color: $main-color;
                position: absolute;
                left: 0;
                top: 1px;
                font-size: 18px;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }
}
.events-details-desc {
    padding: {
        left: 25px;
        right: 25px;
    };
    h3 {
        margin-bottom: 12px;
        font: {
            size: 25px;
        };
        &:not(:first-child) {
            margin-top: 30px;
        }
    }
}
.events-details-image {
    position: relative;
    margin-bottom: 50px;

    #timer {
        position: absolute;
        z-index: 2;
        right: 20px;
        bottom: 20px;

        div {
            background-color: transparent;
            color: $white-color;
            width: 130px;
            height: 130px;
            position: relative;
            z-index: 1;
            border-radius: 5px;
            font: {
                size: 50px;
                weight: 800;
            };
            margin: {
                left: 8px;
                right: 8px;
            };
            span {
                display: block;
                margin-top: -6px;
                text-transform: uppercase;
                font: {
                    size: 18px;
                    weight: 600;
                };
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                border-radius: 5px;
                background-color: $black-color;
                opacity: .90;
                z-index: -1;
            }
        }
    }
}
.events-details-info {
    background-color: #f8f9f8;
    border-radius: 5px;
    padding: 35px;
    
    .info {
        margin-bottom: 0;
        list-style-type: none;
        padding-left: 0;

        li {
            border-bottom: 1px solid #e5e5e7;
            color: $optional-color;
            font: {
                size: 17px;
                weight: 600;
            };
            padding: {
                top: 15px;
                bottom: 14px;
            };
            span {
                font-weight: 800;
                color: $black-color;
                position: relative;
            }
            &.price {
                padding-bottom: 10px;
                color: $main-color;
                font: {
                    size: 28px;
                    weight: 800;
                };
                span {
                    color: $black-color;
                    font: {
                        size: 17px;
                        weight: 800;
                    };
                }
            }
            &:first-child {
                padding-top: 0;
            }
            .payment-method {
                img {
                    margin-left: 5px;
                }
            }
        }
    }
    .btn-box {
        text-align: center;
        margin-top: 30px;

        .default-btn {
            display: block;
            padding: {
                right: 30px;
                left: 30px;
                top: 12px;
                bottom: 12px;
            };
            i {
                position: relative;
                left: 0;
                top: 2px;
                margin-right: 8px;
                line-height: 1;
                font: {
                    weight: normal;
                    size: 19px;
                };
            }
        }
        p {
            margin-top: 15px;
            font-weight: 600;

            a {
                color: $main-color;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    .events-share {
        text-align: center;
        margin-top: 25px;
        
        .share-info {
            display: inline-block;
            position: relative;
            cursor: pointer;

            span {
                display: inline-block;
                color: $main-color;
                line-height: 1;
                font: {
                    size: $font-size;
                    weight: 700;
                };
                i {
                    color: $black-color;
                }
            }
            .social-link {
                padding-left: 0;
                list-style-type: none;
                margin: {
                    bottom: 0;
                    top: 12px;
                };
                li {
                    display: inline-block;
                    margin-right: 3px;
        
                    &:last-child {
                        margin-right: 0;
                    }
                    a {
                        width: 30px;
                        height: 30px;
                        text-align: center;
                        background-color: #e1e1e1;
                        font-size: 18px;
                        color: $black-color;
                        position: relative;
                        border-radius: 2px;
        
                        i {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-48%);
                        }
                        &:hover {
                            background-color: $main-color;
                            color: $white-color;
                        }
                    }
                }
            }
        }
    }
}
.events-details-location {
    margin-bottom: 40px;

    iframe {
        height: 400px;
        width: 100%;
        border: none;
    }
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    background-color: $black-color;
    height: 100vh;
}
.error-content {
    text-align: center;

    h3 {
        color: $white-color;
        margin: {
            bottom: 20px;
            top: 45px;
        };
        font: {
            size: 40px;
        };
    }
    p {
        color: rgba(255, 255, 255, .7);
        max-width: 550px;
        margin: {
            left: auto;
            right: auto;
            bottom: 0;
        };
    }
    .btn-box {
        margin-top: 30px;

        .default-btn {
            margin: {
                left: 7px;
                right: 7px;
            };
            span {
                background-color: $white-color;
            }
            i {
                font-weight: normal;
            }
            &:hover {
                color: $black-color;
            }
        }
    }
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
    position: relative;
    z-index: 1;

    &.bg-color {
        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            right: 0;
            z-index: -1;
            height: 267px;
            background-color: #f1f8fb;
        }
    }
    &.bg-23173a {
        background-color: $black-color;

        .section-title {
            h2 {
                color: $white-color;

                .overlay {
                    background-color: $white-color;
                }
            }
            p {
                color: $white-color;
            }
        }
    }
}
.single-testimonials-item {
    margin-bottom: 30px;
    background-color: transparent;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    padding: 130px 50px 50px;
    text-align: center;

    p {
        margin-bottom: 0;
        font-size: 18px;
    }
    &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $white-color;
        z-index: -1;
        height: 87%;
        border-radius: 5px;
        right: 0;
    }
    &::after {
        content: "\f106";
        position: absolute;
        left: 0;
        color: $black-color;
        top: -20px;
        line-height: 1;
        z-index: -1;
        right: 0;
        opacity: .05;
        font: {
            family: Flaticon;
            size: 150px;
        };
    }
    .client-info {
        margin-top: 25px;

        img {
            width: 50px !important;
            height: 50px;
            display: inline-block !important;
            border-radius: 50%;
        }
        .title {
            text-align: left;
            margin-left: 15px;

            h3 {
                font-size: 18px;
                margin-bottom: 3px;
            }
            span {
                color: $optional-color;
                font: {
                    size: 15px;
                    weight: 600;
                };
            }
        }
    }
}
.single-testimonials-box {
    margin-bottom: 30px;
    background-color: $white-color;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    padding: 70px 50px 40px;
    text-align: center;
    margin-top: 40px;

    &.bg-fafafb {
        background-color: #fafafb;
    }
    img {
        width: 80px !important;
        height: 80px;
        display: inline-block !important;
        border-radius: 50%;
        border: 5px solid #e3fbff;
        position: absolute;
        left: 0;
        right: 0;
        top: -40px;
        margin: auto;
    }
    p {
        margin-bottom: 0;
        font-size: 18px;
    }
    &::after {
        content: "\f106";
        position: absolute;
        bottom: 0;
        color: $black-color;
        line-height: 1;
        z-index: -1;
        right: 20px;
        opacity: .05;
        font: {
            family: Flaticon;
            size: 140px;
        };
    }
    .client-info {
        margin-top: 25px;

        h3 {
            font-size: 20px;
            margin-bottom: 5px;
        }
        span {
            color: $optional-color;
            font: {
                size: 15px;
                weight: 600;
            };
        }
    }
}
.testimonials-view-btn {
    margin-top: 25px;

    .default-btn {
        background-color: transparent;
        color: $main-color;
        border: 1px solid #eae1d6;
        margin-bottom: -7px;

        span {
            background-color: $main-color;
        }
        &:hover {
            color: $white-color;
            border-color: $main-color;
        }
    }
}
.testimonials-slides {
    .swiper-button-prev, .swiper-button-next {
        width: 40px;
        top: 0;
        height: 40px;
        border-radius: 50%;
        background-color: $white-color;
        color: $black-color;
        border: 1px solid #eae2d7; 
        transition: $transition; 
        font-size: 16px;
        margin-top: 0;

        &::after {
            font-size: 20px;
        }

        &:hover {
            background-color: $main-color;
            border-color: $main-color;
            box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
            color: $white-color;
        }
    }
}
.shape-img1 {
    position: absolute;
    left: 10%;
    top: 2%;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 10s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    position: relative;
    z-index: 1;

    &.ptb-70 {
        padding-bottom: 40px;
    }
    &.bor-top-1 {
        border-top: 1px solid #eee;
    }
}
.single-partner-item {
    text-align: center;
    margin-bottom: 30px;
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    position: relative;
    z-index: 1;

    .row {
        &.align-items-center {
            margin: {
                left: -40px;
                right: -40px;
            };
            .col-lg-4 {
                padding: {
                    left: 40px;
                    right: 40px;
                };
                &:nth-child(2) {
                    padding: {
                        left: 0;
                        right: 0;
                    };
                }
            }
        }
    }
}
.single-blog-post {
    margin-bottom: 30px;
    background-color: $white-color;
    transition: $transition;

    .post-image {
        border-radius: 5px 5px 0 0;

        a {
            display: block;
            border-radius: 5px 5px 0 0;

            img {
                border-radius: 5px 5px 0 0;
                width: 100%;
            }
        }
    }
    .post-content {
        padding: 25px;

        .post-meta {
            list-style-type: none;
            padding-left: 0;
            margin: {
                bottom: 18px;
                left: -7px;
                right: -7px;
            };
            li {
                color: $optional-color;
                font-size: 15px;
                position: relative;
                padding: {
                    left: 29px;
                    right: 7px;
                };
                .post-author {        
                    img {
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                    }
                    span {
                        font: {
                            weight: 600;
                            size: 15px;
                        };
                    }
                }
                i {
                    color: $main-color;
                    position: absolute;
                    left: 7px;
                    top: 0;
                }
                &:first-child {
                    padding-left: 7px;
                }
            }
        }
        h3 {
            margin-bottom: 0;
            line-height: 1.3;
            font-size: 24px;

            a {
                display: inline-block;
            }
        }
    }
    &.bg-fffbf5 {
        background-color: #fffbf5;
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
    }
}
.blog-items {
    margin: {
        left: -15px;
        right: -15px;
    };
}
.vector-shape1 {
    position: absolute;
    left: 12%;
    z-index: -1;
    bottom: 40px;

    img {
        animation: {
            name: rotatemetwo;
            duration: 350s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
.vector-shape2 {
    position: absolute;
    right: 10%;
    top: 230px;
    z-index: -1;

    img {
        animation: {
            name: rotateme;
            duration: 350s;
            iteration-count: infinite;
            timing-function: linear;
        };
    }
}
@keyframes rotatemetwo {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-desc {
    .article-image {
        img {
            width: 100%;
        }
    }
    .article-image-slides {
        &.owl-theme {
            .owl-nav {
                margin-top: 0;
                opacity: 0;
                visibility: hidden;
                transition: $transition;
    
                [class*=owl-] {
                    color: #87a1c1;
                    font-size: 18px;
                    margin: 0;
                    padding: 0;
                    background: $white-color;
                    width: 45px;
                    height: 45px;
                    line-height: 40px;
                    position: absolute;
                    left: 20px;
                    transition: $transition;
                    top: 50%;
                    transform: translateY(-50%);
                    padding: {
                        left: 5px !important;
                    };
                    border: {
                        radius: 50%;
                        style: solid;
                        color: $white-color;
                        width: 1.5px;
                    };
                    &.owl-next {
                        left: auto;
                        right: 20px;
                        padding: {
                            left: 3px !important;
                        };
                    }
                    &:hover {
                        background-color: $main-color;
                        color: $white-color;
                        border-color: $main-color;
                    }
                }
            }
            &:hover {
                .owl-nav {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
    .article-video {
        iframe {
            width: 100%;
            height: 400px;
            border: none !important;
        }
    }
    .article-content {
        margin-top: 30px;

        .entry-meta {
            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;
    
                li {
                    margin-right: 20px;
                    position: relative;
                    display: inline-block;
                    border-right: 1px solid #eeeeee;
                    font: {
                        weight: 600;
                        size: $font-size;
                    };
                    padding: {
                        right: 20px;
                        left: 45px;
                    };
                    i {
                        font-size: 32px;
                        color: #cfcfcf;
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    span {
                        display: block;
                        color: $main-color;
                        text-transform: uppercase;
                        margin-bottom: 2px;
                        font: {
                            size: 14px;
                            weight: 600;
                        };
                    }
                    a {
                        display: inline-block;
                        font: {
                            size: $font-size;
                            weight: 700;
                        };
                    }
                    &:last-child {
                        padding-right: 0;
                        margin-right: 0;
                        border-right: none;
                    }
                }
            }
        }
        h3 {
            margin: {
                bottom: 15px;
                top: 28px;
            };
            font: {
                size: 24px;
            };
        }
        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: flex;
                flex-wrap: wrap;
                margin: {
                    right: -10px;
                    left: -10px;
                    bottom: 30px;
                    top: 30px;
                }
                li {
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;
                    padding: {
                        right: 10px;
                        left: 10px;
                    }
                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }
        .features-list {
            padding-left: 0;
            list-style-type: none;
            margin: {
                top: 25px;
                bottom: 30px;
            };
            li {
                margin-bottom: 15px;
                position: relative;
                padding-left: 26px;
                font-weight: 600;
                color: $optional-color;

                i {
                    color: $main-color;
                    display: inline-block;
                    font-size: 20px;
                    position: absolute;
                    left: 0;
                    top: 1px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .article-footer {
        display: flex;
        flex-wrap: wrap;
        border-top: 1px solid #eeeeee;
        padding-top: 30px;
        margin: {
            top: 30px;
        };
        .article-tags {
            flex: 0 0 50%;
            max-width: 50%;
            color: $optional-color;
            
            span {
                display: inline-block;
                color: $black-color;
                margin-right: 10px;
                position: relative;
                top: 3px;
                font: {
                    size: 20px;
                };
            }
            a {
                display: inline-block;
                font: {
                    weight: 700;
                };
                position: relative;
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
                &:last-child::before {
                    display: none;
                }
                &::before {
                    content: ',';
                    position: absolute;
                    right: -4px;
                }
            }
        }
        .article-share {
            flex: 0 0 50%;
            max-width: 50%;
            
            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;
                margin: {
                    bottom: 0;
                };
                li {
                    display: inline-block;

                    span {
                        display: inline-block;
                        margin-right: 5px;
                        font-weight: 700;
                        position: relative;
                        top: -1px;
                    }
                    a {
                        display: block;
                        width: 32px;
                        height: 32px;
                        line-height: 34px;
                        border-radius: 50%;
                        background-color: $main-color;
                        color: $white-color;
                        border: 1px solid $main-color;
                        text-align: center;
                        font-size: 17px;
                        margin-left: 5px;
        
                        &:hover, &:focus {
                            color: $main-color;
                            background-color: transparent;
                        }
                        &.facebook {
                            background-color: #3b5998;
                            border-color: #3b5998;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #3b5998;
                                background-color: transparent;
                            }
                        }
                        &.twitter {
                            background-color: #1da1f2;
                            border-color: #1da1f2;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #1da1f2;
                                background-color: transparent;
                            }
                        }
                        &.linkedin {
                            background-color: #007bb5;
                            border-color: #007bb5;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #007bb5;
                                background-color: transparent;
                            }
                        }
                        &.instagram {
                            background-color: #c13584;
                            border-color: #c13584;
                            color: $white-color;

                            &:hover, &:focus {
                                color: #c13584;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
        }
    }
    .article-author {
        margin-top: 30px;
        border-radius: 5px;
        background: #fdfcfc;

        .author-profile-header {
            height: 115px;
            border-radius: 5px 5px 0 0;
            background: {
                color: $main-color;
                image: url(./img/bg.jpg);
                position: center center;
                size: cover;
                repeat: no-repeat;
            };
        }
        .author-profile {
            padding: 0 25px 25px;

            .author-profile-title {
                position: relative;
                z-index: 1;
                margin: {
                    top: -45px;
                };
                img {
                    display: inline-block;
                    border: 3px solid $white-color;
                    width: 100px;
                    height: 100px;
                    margin-bottom: 25px;
                }
                h4 {
                    margin-bottom: 5px;
                    font: {
                        size: 22px;
                    };
                }
                span {
                    display: block;
                    margin-bottom: 12px;
                    font-size: 16px;
                    color: $optional-color;
                }
                p {
                    font-size: 16px;
                }
            }
        }
    }
}
blockquote, .blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    margin: {
        bottom: 20px;
        top: 20px;
    };
    p {
        color: $black-color;
        line-height: 1.6;
        margin-bottom: 0;
        font: {
            style: italic;
            weight: 700;
            size: 22px !important;
        };
    }
    cite {
        display: none;
    }
    &::before {
        color: #efefef;
        position: absolute;
        animation: fade-up 1.5s infinite linear;
        left: 50px;
        top: -50px;
        z-index: -1;
        content: "\f106";
        font: {
            family: Flaticon;
            size: 135px;
        };
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 3px;
        background-color: $main-color;
        margin: {
            top: 20px;
            bottom: 20px;
        };
    }
}
.tracer-post-navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between !important;
    margin: {
        top: 30px;
    };
    padding: {
        top: 30px;
        bottom: 30px;
    };
    border: {
        top: 1px solid #eeeeee;
        bottom: 1px solid #eeeeee;
    };
}
.prev-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    padding: {
        right: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-prev {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .prev-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-prev {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-right: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: $font-size;
                weight: 700;
            };
        }
    }
    .prev-link-info-wrapper {
        color: $black-color;
        transition: $transition;
    }
    .prev-title {
        display: inline-block;
        font: {
            weight: 800;
            size: $font-size;
        };
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 7px;
        font: {
            weight: 600;
            size: 16px;
        };
    }
}
.next-link-wrapper {
    flex: 0 0 50%;
    max-width: 50%;
    text-align: right;
    padding: {
        left: 15px;
    };
    a {
        display: flex;
        align-items: center;

        &:hover {
            .image-next {
                &::after {
                    opacity: 1;
                    visibility: visible;
                }
                .post-nav-title {
                    opacity: 1;
                    visibility: visible;
                }
            }
            .next-link-info-wrapper {
                color: $main-color;
            }
        }
    }
    .image-next {
        display: inline-block;
        min-width: 100px;
        width: 100px;
        border-radius: 5px;
        overflow: hidden;
        vertical-align: top;
        margin-left: 20px;
        position: relative;
        transition: $transition;

        img {
            border-radius: 5px;
        }
        &::after {
            display: block;
            content: '';
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 5px;
            opacity: 0;
            background-color: $main-color;
            visibility: hidden;
            transition: $transition;
        }
        .post-nav-title {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            margin: 0 auto;
            text-align: center;
            text-transform: uppercase;
            z-index: 2;
            color: $white-color;
            opacity: 0;
            visibility: hidden;
            transition: $transition;
            font: {
                size: $font-size;
                weight: 700;
            };
        }
    }
    .next-link-info-wrapper {
        transition: $transition;
        color: $black-color;
    }
    .next-title {
        display: inline-block;
        font: {
            weight: 800;
            size: $font-size;
        };
    }
    .meta-wrapper {
        display: block;
        color: $optional-color;
        text-transform: capitalize;
        margin-top: 7px;
        font: {
            weight: 600;
            size: 16px;
        };
    }
}
.comments-area {
    margin-top: 30px;

    .comments-title {
        line-height: initial;
        margin: {
            bottom: 30px;
        };
        font: {
            size: 24px;
        };
    }
    ol, ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .comment-list {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }
    .children {
        margin-left: 20px;
    }
    .comment-body {
        border-bottom: 1px dashed #eeeeee;
        padding-left: 70px;
        color: $black-color;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px dashed #ded9d9;
                color: $black-color;
                display: inline-block;
                padding: 7px 20px 4px;
                border-radius: 30px;
                text-transform: uppercase;
                font: {
                    size: 13px;
                    weight: bold;
                };
                &:hover {
                    color: $white-color;
                    background-color: $main-color;
                    border-color: $main-color;
                }
            }
        }
    }
    .comment-author {
        font-size: $font-size;
        margin-bottom: 8px;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }
        .fn {
            font: {
                weight: 800;
            };
        }
        .says {
            display: none;
        }
    }
    .comment-metadata {
        margin-bottom: .8em;
        color: $optional-color;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font: {
            size: 13px;
            weight: 400;
        };
        a {
            display: inline-block;
            color: $optional-color;

            &:hover {
                color: $main-color;
            }
        }
    }
    .comment-content {
        p {
            font: {
                size: 16.5px;
                weight: 500;
            };
        }
    }
    .comment-respond {
        margin-top: 30px;

        .comment-reply-title {
            margin-bottom: 0;
            font: {
                size: 24px;
            };
            #cancel-comment-reply-link {
                display: inline-block;
            }
        }
        .comment-form {
            overflow: hidden;
        }
        .comment-notes {
            margin: {
                bottom: 20px;
                top: 10px;
            };
            .required {
                color: red;
            }
        }
        .comment-form-comment {
            float: left;
            width: 100%;
        }
        label {
            display: none;
        }
        input[type="date"], input[type="time"], input[type="datetime-local"], input[type="week"], input[type="month"], input[type="text"], input[type="email"], input[type="url"], input[type="password"], input[type="search"], input[type="tel"], input[type="number"], textarea {
            display: block;
            width: 100%;
            background-color: #f4f4f4;
            border: none;
            padding: 1px 0 0 15px;
            height: 50px;
            outline: 0;
            border-radius: 3px;
            color: $black-color;
            font: {
                weight: 600;
                size: 15px;
            };
            &::placeholder {
                transition: $transition;
                color: $optional-color;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        textarea {
            height: auto !important;
            padding-top: 15px;
        }
        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }
        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }
        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }
        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin: {
                bottom: 20px;
                top: 15px;
            };
            input {
                position: absolute;
                left: 0;
                top: 7px;
            }
            label {
                display: inline-block;
                margin: 0;
                color: $optional-color;
                font: {
                    weight: normal;
                    size: $font-size;
                };
            }
        }
        .form-submit {
            float: left;
            width: 100%;

            input {
                background: $main-color;
                border: none;
                color: $white-color;
                padding: 12px 30px 10px;
                display: inline-block;
                cursor: pointer;
                text-transform: capitalize;
                transition: $transition;
                border-radius: 0;
                font: {
                    weight: 700;
                    size: 16px;
                };
                &:hover, &:focus {
                    color: $white-color;
                    background: $black-color;
                }
            }
        }
    }
}

/*================================================
Project Start Area CSS
=================================================*/
.project-start-area {
    position: relative;
    z-index: 1;

    &.bg-color {
        background-color: $black-color;

        .project-start-content {
            h2 {
                color: $white-color;
            }
            p {
                color: $white-color;
            }
            .default-btn {
                span {
                    background-color: $white-color;
                }
                &:hover {
                    color: $black-color;
                }
            }
        }
    }
}
.project-start-image {
    text-align: center;
}
.project-start-content {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 44px;
        };
    }
    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    .default-btn {
        margin-top: 5px;
    }
}
.vector-shape3 {
    position: absolute;
    right: 0;
    bottom: 50px;
    z-index: -1;
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background: transparent url(./img/page-title-bg.jpg) right top no-repeat;
    border-bottom: 1px solid #fafafa;
    padding: {
        top: 80px;
        bottom: 20px;
    };
    .shape-img1 {
        top: auto;
        bottom: 25%;
        left: 5%;
    }
    &.page-title-style-two {
        padding: {
            top: 120px;
            bottom: 120px;
        };
    }
}
.page-title-content {
    h2 {
        margin-bottom: 0;
        font: {
            size: 44px;
        };
    }
    ul {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 15px;
        };
        li {
            display: inline-block;
            margin-right: 25px;
            position: relative;
            font: {
                size: 16.5px;
                weight: 700;
            };
            a {
                display: inline-block;
            }
            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                color: $main-color;
                position: absolute;
                right: -18px;
                top: 6.5px;
                line-height: 1;
                content: "\f104";
                font: {
                    family: Flaticon;
                    size: 11px;
                };
            }
        }
    }
    &.text-center {
        ul {
            li {
                margin: {
                    left: 10px;
                    right: 10px;
                };
                &:first-child {
                    margin-left: 0;
                }
            }
        }
    }
}
.shape-img2 {
    position: absolute;
    bottom: 10%;
    z-index: -1;
    right: 30px;
    animation: rotate3d 4s linear infinite;
    opacity: .25;
}
.shape-img3 {
    position: absolute;
    left: 20%;
    top: 5%;
    z-index: -1;
    animation: movebounce 5s linear infinite;
    opacity: .15;
}
@keyframes rotate3d {
    0% {
        transform: rotateY(0deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
    margin-top: 35px;

    .page-numbers {
        width: 40px;
        height: 40px;
        background-color: $white-color;
        box-shadow: 0 0px 30px 5px #e4e4ee;
        color: $black-color;
        text-align: center;
        display: inline-block;
        border-radius: 3px;
        line-height: 41px;
        position: relative;
        margin: {
            left: 3px;
            right: 3px;
        };
        font: {
            size: $font-size;
            weight: 800;
        };
        &:hover, &.current {
            color: $white-color;
            background-color: $main-color;
        }
        i {
            position: relative;
            top: 1.5px;
        }
    }
}

/*================================================
Widget Sidebar CSS
=================================================*/
.widget-area {
    padding-left: 15px;

    &.widget-left-sidebar {
        padding: {
            right: 15px;
            left: 0;
        };
    }
    .widget {
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            margin-bottom: 25px;
            position: relative;
            padding-bottom: 7px;
            border-bottom: 1px solid #eeeeee;
            font: {
                size: 22px;
            };
            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 50px;
                height: 1px;
                bottom: -1px;
                background-color: $main-color;
            }
        }
    }
    .widget_search {
        form {
            position: relative;
            
            .screen-reader-text {
                display: none;
            }
            label {
                display: block;
                margin-bottom: 0;
            }
            .search-field {
                height: 50px;
                color: $black-color;
                background-color: #f2f4f5;
                display: block;
                width: 100%;
                border-radius: 5px;
                padding: 0 0 0 15px;
                border: none;
                transition: $transition;
                font: {
                    size: $font-size;
                    weight: 500;
                };
                &::placeholder {
                    color: $optional-color;
                    transition: $transition;
                }
                &:focus {
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
            button {
                border: none;
                background-color: transparent;
                color: $main-color;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 5px;
                padding: 0;
                transition: $transition;
                top: 5px;
                font-size: 20px;

                i {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &:hover, &:focus {
                    border-radius: 5px;
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
    .widget_tracer_posts_thumb {
        position: relative;
        overflow: hidden;
        
        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 5px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: $white-color;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $optional-color;
                    text-transform: uppercase;
                    margin: {
                        top: -2px;
                        bottom: 5px;
                    };
                    font: {
                        size: 13px;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 800;
                    };
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                overflow: hidden;
                color: $black-color;
                padding-left: 18px;
                font: {
                    weight: 700;
                    size: 15px;
                };
                &:last-child {
                    margin-bottom: 0;
                }
                &::before {
                    background: $main-color;
                    height: 8px;
                    width: 8px;
                    content: '';
                    border-radius: 50%;
                    left: 0;
                    top: 7px;
                    position: absolute;
                }
                a {
                    color: $black-color;
                    display: inline-block;

                    &:hover {
                        color: $main-color;
                    }
                }
                .post-count {
                    font-size: 14px;
                    display: inline-block;
                    position: relative;
                    top: 1px;
                }
            }
        }
    }
    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 17px;
        }
    }
    .tagcloud {
        a {
            display: inline-block;
            background: #f5f5f5;
            color: $black-color;
            padding: 7px 15px;
            border: none;
            border-radius: 3px;
            font: {
                weight: 700;
                size: 14px !important;
            };
            margin: {
                top: 8px;
                right: 4px;
            };
            &:hover, &:focus {
                color: $white-color;
                background-color: $main-color;
            }
        }
    }
    .widget_popular_products {
        position: relative;
        overflow: hidden;
        
        .widget-title {
            margin-bottom: 30px;
        }
        .item {
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: left;
                height: 110px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 110px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 110px;
                    height: 110px;
                    display: inline-block;
                    border-radius: 3px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: $white-color;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin: {
                        bottom: 6px;
                        top: -5px;
                    };
                    font: {
                        size: 18px;
                        weight: 800;
                        style: italic;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 800;
                    };
                    a {
                        display: inline-block;
                    }
                }
                .rating {
                    margin-top: 8px;

                    i {
                        color: #f6b500;
                        display: inline-block;
                        margin-right: -2px;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .filter_by_price {
        .irs--flat {
            .irs-bar {
                background-color: $main-color;
            }
            .irs-handle>i:first-child {
                background-color: $main-color;
            }
        }
        .irs--flat .irs-from, .irs--flat .irs-to, .irs--flat .irs-single {
            background-color: $main-color;
            color: $white-color;
        }
    }
    .widget_recent_courses {
        position: relative;
        overflow: hidden;
        
        .widget-title {
            margin-bottom: 30px;
        }
        .item {
            overflow: hidden;
            margin-bottom: 20px;
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
                border-bottom: none;
                padding-bottom: 0;
            }
            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                display: block;
                position: relative;
                width: 80px;
                margin-right: 15px;
                z-index: 1;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;
                    border-radius: 3px;
                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    };
                }
                &::before, &::after {
                    transition: all .8s cubic-bezier(.2, 1, .22, 1);
                    content: '';
                    background-color: $white-color;
                    position: absolute;
                    z-index: 1;
                    top: 50%;
                    left: 50%;
                    opacity: 0;
                    transform: translate(-50%, -50%);
                }
                &::before {
                    width: 40px;
                    height: 1px;
                    left: 100%;
                }
                &::after {
                    height: 40px;
                    width: 1px;
                    top: 0;
                }
            }
            .info {
                overflow: hidden;
                margin-top: 5px;

                span {
                    display: block;
                    color: $main-color;
                    text-transform: uppercase;
                    margin: {
                        bottom: 6px;
                        top: -5px;
                    };
                    font: {
                        size: 18px;
                        weight: 800;
                        style: italic;
                    };
                }
                .title {
                    margin-bottom: 0;
                    line-height: 1.4;
                    font: {
                        size: 17px;
                        weight: 800;
                    };
                    a {
                        display: inline-block;
                    }
                }
            }
            &:hover {
                .thumb {
                    &::before, &::after {
                        opacity: 1;
                        top: 50%;
                        left: 50%;
                    }
                }
            }
        }
    }
    .widget_insight {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                margin-bottom: -1px;

                a {
                    position: relative;
                    display: block;
                    padding: 15px 25px 15px 40px;
                    color: $optional-color;
                    border: 1px solid #eeeeee;
                    font: {
                        size: $font-size;
                        weight: 700;
                    };
                    &::before {
                        width: 5px;
                        height: 5px;
                        transition: $transition;
                        background-color: $optional-color;
                        content: '';
                        position: absolute;
                        left: 25px;
                        top: 50%;
                        transform: translateY(-50%);
                        border-radius: 50%;
                    }
                    &:hover, &.active {
                        border-color: $main-color;
                        background-color: $main-color;
                        color: $white-color;

                        &::before {
                            background-color: $white-color;
                        }
                    }
                }
                &.active {
                    a {
                        border-color: $main-color;
                        background-color: $main-color;
                        color: $white-color;

                        &::before {
                            background-color: $white-color;
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Profile Authentication Area CSS
=================================================*/
.login-form {
    padding: 40px 50px;
    border-radius: 5px;
    background-color: $white-color;
    box-shadow: 0 0 10px rgba(51,51,51,.1);
    margin-right: 30px;

    h2 {
        margin-bottom: 25px;
        font: {
            size: 35px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: #111111;
                font-weight: 700;
            }
        }
        .remember-me-wrap {
            margin-bottom: 0;

            [type="checkbox"]:checked, [type="checkbox"]:not(:checked) {
                display: none;
            }
            [type="checkbox"]:checked + label, [type="checkbox"]:not(:checked) + label {
                position: relative;
                padding-left: 28px;
                cursor: pointer;
                line-height: 20px;
                display: inline-block;
                margin-bottom: 0;
                color: $optional-color;
                font: {
                    weight: 600;
                };
            }
            [type="checkbox"]:checked + label:before, [type="checkbox"]:not(:checked) + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 19px;
                height: 19px;
                transition: all 0.2s ease;
                border: 1px solid #f5f5f5;
                border-radius: 3px;
                background: #f5f5f5;
            }
            [type="checkbox"]:checked + label:after, [type="checkbox"]:not(:checked) + label:after {
                content: '';
                width: 8px;
                height: 8px;
                background: $main-color;
                position: absolute;
                top: 5.5px;
                left: 6px;
                -webkit-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
            [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                -webkit-transform: scale(0);
                transform: scale(0);
            }
            [type="checkbox"]:checked + label:after {
                opacity: 1;
                -webkit-transform: scale(1);
                transform: scale(1);
            }
            [type="checkbox"]:hover + label:before {
                border-color: $main-color;
            }
            [type="checkbox"]:checked + label:before {
                border-color: $main-color;
            }
        }
        .lost-your-password-wrap {
            text-align: right;

            a {
                display: inline-block;
                position: relative;
                font-weight: 600;
                line-height: 1.3;

                &::before {
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    content: '';
                    transition: $transition;
                    background-color: #eeeeee;
                }
                &::after {
                    width: 0;
                    height: 1px;
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    bottom: 0;
                    content: '';
                    background-color: $main-color;
                }
                &:hover {
                    &::before {
                        width: 0;
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
        }
        button {
            margin-top: 22px;
            border: none;
            display: block;
            text-align: center;
            overflow: hidden;
            color: $white-color;
            background-color: $main-color;
            transition: $transition;
            width: 100%;
            border-radius: 5px;
            padding: 14.5px 30px;
            font: {
                weight: 700;
                size: $font-size;
            };
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}
.register-form {
    padding: 30px;
    border: 1px solid #f2f2f2;
    h2 {
        margin-bottom: 25px;
        font: {
            size: 35px;
        };
    }
    form {
        .form-group {
            margin-bottom: 25px;

            label {
                display: block;
                margin-bottom: 10px;
                color: $black-color;
                font-weight: 700;
            }
        }
        .description {
            font-style: italic;
            font-size: 13.5px;
            margin: {
                top: -10px;
                bottom: 0;
            };
        }
        button {
            margin-top: 22px;
            border: none;
            display: block;
            text-align: center;
            overflow: hidden;
            color: $white-color;
            background-color: $main-color;
            transition: $transition;
            width: 100%;
            border-radius: 5px;
            padding: 14.5px 30px;
            font: {
                weight: 700;
                size: $font-size;
            };
            &:hover {
                background-color: $black-color;
                color: $white-color;
            }
        }
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    height: 100vh;
    position: relative;
    z-index: 1;
    background: #f6f7fb;
    background: { 
        position: top center;
        size: cover;
        repeat: no-repeat;
    };
}
.coming-soon-content {
    max-width: 700px;
    background: $white-color;
    border-radius: 5px;
    overflow: hidden;
    text-align: center;
    padding: 40px 60px;
    box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, .05);
    margin: {
        left: auto;
        right: auto;
    };
    .logo {
        display: inline-block;
    }
    h2 {
        font: {
            size: 44px;
        };
        margin: {
            top: 30px;
            bottom: 0;
        };
    }
    #timer {
        margin-top: 40px;

        div {
            background-color: $black-color;
            color: $white-color;
            width: 100px;
            height: 105px;
            border-radius: 5px;
            font: {
                size: 40px;
                weight: 800;
            };
            margin: {
                left: 10px;
                right: 10px;
            };
            span {
                display: block;
                margin-top: -2px;
                font: {
                    size: 17px;
                    weight: 600;
                };
            }
        }
    }
    form {
        position: relative;
        max-width: 500px;
        margin: {
            left: auto;
            right: auto;
            top: 50px;
        };
        .form-group {
            margin-bottom: 25px;
            width: 100%;
            position: relative;

            .label-title {
                margin-bottom: 0;
                position: absolute;
                display: block;
                left: 0;
                top: 0;
                pointer-events: none;
                width: 100%;
                height: 100%;
                color: $black-color;

                i {
                    position: absolute;
                    left: 0;
                    transition: $transition;
                    top: 9px;
                    font: {
                        size: 22px;
                    };
                }
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 0;
                    height: 2px;
                    transition: $transition;
                    background: $main-color;
                }
            }
            .input-newsletter {
                border-radius: 0;
                border: none;
                border-bottom: 2px solid #eeeeee;
                padding: 0 0 0 32px;
                color: $black-color;
                height: 45px;
                display: block;
                width: 100%;
                transition: $transition;
                font: {
                    size: 16px;
                    weight: 400;
                };
                &::placeholder {
                    color: #A1A1A1;
                    transition: $transition;
                }
                &:focus {                    
                    &::placeholder {
                        color: transparent;
                    }
                }
            }
        }
        .default-btn {
            border-radius: 0;
        }
        .validation-danger {
            margin-top: 15px;
            color: red;
        }
        .validation-success {
            margin-top: 15px;
        }
        p {
            margin: {
                bottom: 0;
                top: 20px;
            };
        }
    }
}

/*================================================
Privacy Policy Area CSS
=================================================*/
.privacy-policy-content {
    img {
        border-radius: 5px;
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 24px;
        };
    }
    .blockquote, blockquote {
        margin-bottom: 30px;
        background-color: #e6f2f5;
        text-align: left !important;
        padding: 25px !important;

        p {
            margin-bottom: 0;
            line-height: 1.8;
            font: {
                size: $font-size !important;
                weight: 700;
            };
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: $optional-color;
            line-height: 1.8;
            font: {
                weight: 600;
                size: $font-size;
            };
        }
    }
}

/*================================================
Terms of Service Area CSS
=================================================*/
.terms-of-service-content {
    img {
        border-radius: 5px;
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 24px;
        };
    }
    .blockquote, blockquote {
        margin-bottom: 30px;
        background-color: #e6f2f5;
        text-align: left !important;
        padding: 25px !important;

        p {
            margin-bottom: 0;
            line-height: 1.8;
            font: {
                size: $font-size !important;
                weight: 700;
            };
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: $optional-color;
            line-height: 1.8;
            font: {
                weight: 600;
                size: $font-size;
            };
        }
    }
}

/*================================================
Purchase Guide Area CSS
=================================================*/
.purchase-guide-content {
    img {
        border-radius: 5px;
        display: block;
        width: 100%;
        margin-bottom: 30px;
    }
    h3 {
        margin: {
            bottom: 15px;
            top: 25px;
        };
        font: {
            size: 24px;
        };
    }
    .blockquote, blockquote {
        margin-bottom: 30px;
        background-color: #e6f2f5;
        text-align: left !important;
        padding: 25px !important;

        p {
            margin-bottom: 0;
            line-height: 1.8;
            font: {
                size: $font-size !important;
                weight: 700;
            };
        }
        &::after {
            display: none;
        }
        &::before {
            display: none;
        }
    }
    ol, ul {
        margin-top: 20px;

        li {
            margin-bottom: 10px;
            color: $optional-color;
            line-height: 1.8;
            font: {
                weight: 600;
                size: $font-size;
            };
        }
    }
}

/*================================================
Membership Levels Area CSS
=================================================*/
.membership-levels-table {
    .table {
        margin-bottom: 0;

        thead {
            th {
                vertical-align: middle;
                background-color: $main-color;
                white-space: nowrap;
                padding: 20px;
                text-align: center;
                color: rgba(255, 255, 255, .9);
                border: {
                    left: 1px solid #e98f8f;
                    bottom: none;
                    right: 1px solid #e98f8f;
                };
                .price {
                    color: $white-color;
                    margin-bottom: 0;
                    line-height: 1;
                    font: {
                        size: 35px;
                        weight: 800;
                    };
                }
                .title {
                    color: $white-color;
                    text-transform: uppercase;
                    margin: {
                        top: 12px;
                        bottom: 10px;
                    };
                    font: {
                        size: $font-size;
                        weight: 700;
                    };
                }
                &:first-child {
                    border-left: none;
                }
                &:last-child {
                    border-right: none;
                }
                span {
                    display: block;
                }
                .desc {
                    line-height: 1;
                }

                h2 {
                    font-size: 28px;
                }
                h3 {
                    font-size: 25px;
                }
            }
        }
        tbody {
            tr {
                td {
                    padding: 15px 20px;
                    white-space: nowrap;
                    vertical-align: middle;
                    border: none;
                    text-align: center;
                    font: {
                        size: $font-size;
                        weight: 600;
                    };
                    &:first-child {
                        text-align: left;
                    }
                    &.item-check {
                        i {
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            border-radius: 100%;
                            background-color: #0eb582;
                            color: $white-color;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    &.item-none {
                        i {
                            width: 20px;
                            height: 20px;
                            line-height: 20px;
                            border-radius: 100%;
                            background-color: #d85554;
                            color: $white-color;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .select-btn {
                        display: inline-block;
                        color: $black-color;
                        background-color: #efefef;
                        padding: 13px 30px;
                        border-radius: 5px;
                        margin-top: 10px;
                        font: {
                            size: $font-size;
                            weight: 700;
                        };
                        &:hover {
                            color: $white-color;
                            background-color: $main-color
                        }
                    }
                }
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-info-box {
    margin-bottom: 30px;
    position: relative;
    padding-left: 100px;
    z-index: 1;

    .icon {
        width: 75px;
        height: 85px;
        background-color: #f7f7f7;
        border-radius: 3px;
        position: absolute;
        text-align: center;
        left: 0;
        font-size: 40px;
        color: $main-color;
        transition: $transition;
        top: 50%;
        transform: translateY(-50%);

        i {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    h3 {
        margin-bottom: 10px;
        font: {
            size: 24px;
        };
    }
    p {
        margin: {
            bottom: 3px;
        };
        &:last-child {
            margin-bottom: 0;
        }
    }
    .back-icon {
        position: absolute;
        right: 0;
        bottom: -15px;
        z-index: -1;
        color: $black-color;
        line-height: 1;
        opacity: .04;
        font-size: 100px;
        transform: rotate(-5deg);
    }
    &:hover {
        .icon {
            background-color: $main-color;
            color: $white-color;
        }
    }
}
.contact-image {
    margin-top: 20px;
    text-align: center;
}
.contact-form {
    padding: 40px;
    margin-left: 15px;
    box-shadow: 0 0 20px rgba(158,158,158,.16);
    background-color: $white-color;

    form {
        .form-group {
            .form-control {
                height: 55px;
                border-radius: 0;
            }
            textarea.form-control {
                height: auto;
            }
        }
        .help-block {
            ul {
                margin: {
                    bottom: 0;
                    top: 12px;
                };
                li {
                    color: red;
                }
            }
        }
        .default-btn {
            margin-top: 5px;
        }
        #msgSubmit {
            margin: 0 !important;

            &.text-danger, &.text-success {
                margin-top: 15px !important;
            }
        }
    }
}
#map {
    iframe {
        width: 100%;
        height: 500px;
        border: none;
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    position: relative;
    z-index: 1;
}
.subscribe-image {
    text-align: center;
}
.subscribe-content {
    .sub-title {
        position: relative;
        display: block;
        text-transform: uppercase;
        margin-bottom: 15px;
        color: $main-color;
        font: {
            weight: 700;
            size: 15px;
        };
        img {
            margin-right: 5px;
            animation: {
                name: rotateme;
                duration: 40s;
                iteration-count: infinite;
                timing-function: linear;
            };
        }
    }
    h2 {
        margin-bottom: 15px;
        font: {
            size: 44px;
        };
    }
    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }
    .newsletter-form {
        position: relative;
        max-width: 550px;
        margin: {
            top: 30px;
        };
        .input-newsletter {
            display: block;
            width: 100%;
            border: none;
            background-color: #eaeaea;
            color: $black-color;
            height: 52px;
            padding-left: 18px;
            border-radius: 5px;
            font: {
                size: $font-size;
                weight: 600;
            };
            &::placeholder {
                color: #999999;
                transition: $transition;
            }
            &:focus {
                &::placeholder {
                    color: transparent;
                }
            }
        }
        .validation-danger {
            color: red;
            margin-top: 15px;
        }
        .validation-success {
            margin-top: 15px;
        }
        .default-btn {
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 0 5px 5px 0;
            height: 52px;
            transform: unset !important;
        }
    }
}
.vector-shape6 {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: -1;
    opacity: .70;
    
    img {
        animation: movebounce 5s linear infinite;
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    padding-top: 100px;
    position: relative;
    z-index: 1;
    
    &.bg-color {
        background-color: #fefcf8;
    }
}
.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 15px;
        font-size: 15px;
        display: inline-block;
    }
    .social-link {
        padding-left: 0;
        list-style-type: none;
        margin: {
            bottom: 0;
            top: 18px
        };
        li {
            display: inline-block;
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
            a {
                width: 30px;
                height: 30px;
                text-align: center;
                color: $white-color;
                background-color: #6b6b84;
                font-size: 18px;
                position: relative;
                border-radius: 2px;

                i {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-48%);
                }
                &:hover {
                    background-color: $main-color;
                    color: $white-color;
                }
            }
        }
    }
    h3 {
        margin-bottom: 25px;
        font-size: 26px;
    }
    .footer-links-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: $optional-color;
            font-weight: 600;

            a {
                color: $optional-color;
                display: inline-block;
                
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    .footer-contact-info {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 13px;
            color: $optional-color;
            position: relative;
            padding-left: 23px;
            font-weight: 600;

            a {
                color: $optional-color;
                display: inline-block;
                
                &:hover {
                    color: $main-color;
                }
            }
            &:last-child {
                margin-bottom: 0;
            }
            i {
                position: absolute;
                left: 0;
                color: $main-color;
                top: 4px;

                &.bxs-inbox {
                    top: 3px;
                }
            }
        }
    }
}
.footer-bottom-area {
    margin-top: 70px;
    border-top: 1px solid #f3f2ee;
    padding: {
        top: 25px;
        bottom: 25px;
    };
    p {
        i {
            position: relative;
            top: 1.3px;
        }
        a {
            color: $main-color;
            font-weight: 600;

            &:hover {
                color: $black-color;
            }
        }
        strong {
            color: $black-color;
        }
    }
    ul {
        text-align: right;
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            color: $optional-color;
            position: relative;
            display: inline-block;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;

                &::before {
                    display: none;
                }
            }
            &::before {
                content: '';
                position: absolute;
                right: -12px;
                top: 5.6px;
                width: 1px;
                height: 14px;
                background-color: $optional-color;
            }
            a {
                display: block;
                color: $optional-color;

                &:hover {
                    color: $main-color;
                }
            }
        }
    }
}
.footer-map {
    position: absolute;
    right: 20%;
    top: 40%;
    transform: translateY(-40%);
    z-index: -1;
    opacity: .8;
    
    img {
        animation: moveleftbounce 5s linear infinite;
    }
}
@keyframes moveleftbounce {
    0% {
        transform: translateX(0); 
    }
    50% {
        transform: translateX(-20px); 
    }
    100% {
        transform: translateX(0); 
    } 
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    bottom: 20px;
    right: 20px;
    color: $white-color;
    background-color: $black-color;
    z-index: 99;
    width: 45px;
    text-align: center;
    height: 45px;
    border-radius: 50%;
    font-size: 22px;
    transition: .9s;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);

    i {
        position: absolute;
        right: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        margin: {
            left: auto;
            right: auto;
        };
    }
    &:hover {
        background-color: $main-color;
        color: $white-color;
        transition: $transition;
        box-shadow: 0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08);
        transform: translateY(-5px);
    }
}

.lang-didebar {
    position: fixed;
    top: 100px;
    right: 0;
    z-index: 999999;
    a {
        background-color: #000;
        color: #fff;
        display: block;
        padding: 6px 9px;
        font-size: 14px;
        &:last-child {
            background-color: #483e3e;
        }
    }
}